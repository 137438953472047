import React from 'react';
import {
  DidomiSecondaryHeader,
  DidomiBackButtonLink,
  DidomiChip,
  DidomiButton,
} from '@didomi/ui-atoms-react';
import { IntegrationCta } from '@types';

interface IntegrationDetailsHeaderProps {
  logo: string;
  isIntegrationEnabled: boolean;
  integrationCta: IntegrationCta;
  onBackClick: () => void;
  onNavigateToManageClick: () => void;
}

export const IntegrationDetailsHeader = ({
  logo,
  isIntegrationEnabled,
  integrationCta,
  onBackClick,
  onNavigateToManageClick,
}: IntegrationDetailsHeaderProps) => (
  <DidomiSecondaryHeader>
    <div slot="header-title" className="!block">
      <img className="max-h-[56px]" src={logo} alt="" />
    </div>
    <div slot="back-button">
      <DidomiBackButtonLink
        class="cursor-pointer"
        text="Back to integrations"
        onClick={onBackClick}
      />
    </div>

    <div slot="actions">
      {isIntegrationEnabled && (
        <>
          <DidomiChip
            className="mr-xs"
            data-cy="integration-activated"
            data-tracking="marketplace-integration-activated-chip"
            variation="basic"
            basic-type="success"
            label="Activated"
            left-icon="check"
          />
          <DidomiButton
            data-cy="go-to-manage-integration"
            data-tracking="marketplace-go-to-manage-button"
            class="pl-xs border-l border-solid border-neutral-grey-4"
            onClick={onNavigateToManageClick}
          >
            Go to Manage
          </DidomiButton>
        </>
      )}

      {!isIntegrationEnabled && integrationCta && (
        <a href={integrationCta.link} target="_blank" className="flex justify-end" rel="noreferrer">
          <DidomiButton>{integrationCta.text}</DidomiButton>
        </a>
      )}
    </div>
  </DidomiSecondaryHeader>
);
