import { useBlocker } from 'react-router-dom';
import React from 'react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';

interface BlockNavigationModalProps {
  isBlocked: boolean;
  isSaveAllowed: boolean;
  onSaveAndProceedClicked: (proceedNavCb: () => void) => void;
}

/*
 * Display a modal when the user tries to navigate away from a page with unsaved changes.
 * It uses the useBlocker hook from react-router-dom to block the navigation and display the modal.
 * The modal has three buttons: "Don't save", "Keep on editing" and "Save and leave".
 * The "Don't save" button will allow the user to navigate away without saving the changes.
 * The "Keep on editing" button will close the modal and allow the user to continue editing.
 * The "Save and leave" button will save the changes and then navigate away.
 */
export const BlockNavigationModal = ({
  isBlocked,
  isSaveAllowed,
  onSaveAndProceedClicked,
}: BlockNavigationModalProps) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <DidomiModal isOpen={blocker.state === 'blocked'} permanent>
      <DidomiModalHeader
        illustrationName="warning-modal"
        modalTitle="Your changes will be lost"
        modalSubTitle="Please pay attention"
        modalDescription="You're about to leave this page without saving, are you sure?"
        variant="warning"
      />

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={() => blocker.proceed()}>
          Don&apos;t save
        </DidomiButton>
        <DidomiButton onClick={() => blocker.reset()}>Keep on editing</DidomiButton>
        {/* The button is wrapped in the div in order to prevent React issue with dynamic elements removal  */}
        <div>
          {isSaveAllowed && (
            <DidomiButton
              key="save-btn"
              variant="destructive-filled"
              onClick={() => onSaveAndProceedClicked(blocker.proceed)}
            >
              Save and leave
            </DidomiButton>
          )}
        </div>
      </DidomiModalActions>
    </DidomiModal>
  );
};
