import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiBackButtonLink, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';
import { SPA_FULL_NAME } from '@constants';
import { useSubscriptionsApi } from '@hooks';
import { ROUTES } from '@utils';

export const ConfigureSubscriptionLayout = () => {
  const { integrationId } = useParams();
  const { subscription } = useSubscriptionsApi(integrationId);
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const navigate = useNavigate();

  return (
    <section className="p-l pb-20 text-primary-blue-6 flex flex-col h-full">
      <DidomiSecondaryHeader titleText="Configuration" className="block mb-m">
        <div slot="back-button">
          <DidomiBackButtonLink
            class="cursor-pointer"
            text="Back to Manage"
            onClick={() => navigate(ROUTES.MANAGE)}
          />
        </div>

        <div slot="actions">
          <img
            className="max-h-[56px]"
            src={`${ASSETS_URL}/assets/${subscription?.short_details?.logo_url}`}
            alt={subscription?.name}
          />
        </div>
      </DidomiSecondaryHeader>

      <section className="h-full grow">
        <Outlet />
      </section>
    </section>
  );
};
