import React from 'react';
import { DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';

export const FormHelperTooltip = ({ content, slot = 'description' }) => {
  // Slot is used as a prop only if it is defined
  const slotProps = slot ? { slot } : {};

  return (
    <DidomiTooltip
      class="block leading-[0] ml-xxs"
      variant="helper"
      content={content}
      {...slotProps}
    >
      <DidomiIcon name="helper-text"></DidomiIcon>
    </DidomiTooltip>
  );
};
