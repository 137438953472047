import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useMemo } from 'react';
import { ROUTES } from '@utils';
import { useSubscriptionSetup } from './useSubscriptionSetup.hook';

export const useSubscriptionSetupNavigation = () => {
  const navigate = useNavigate();
  const { integrationId } = useParams();
  const { isWithOptions, isWithMapping } = useSubscriptionSetup(integrationId);

  // Dynamically generate steps paths based on the integration configuration
  const stepsPaths = useMemo(() => {
    return [
      ROUTES.CONFIGURE_SUBSCRIPTION_SETUP,
      isWithOptions && ROUTES.CONFIGURE_SUBSCRIPTION_OPTIONS,
      isWithMapping && ROUTES.CONFIGURE_SUBSCRIPTION_MAPPINGS,
      ROUTES.CONFIGURE_SUBSCRIPTION_SUCCESS,
    ].filter(Boolean);
  }, [isWithOptions, isWithMapping]);

  const navigateWithIntegrationId = (path: string) => {
    // Generate absolute path with integrationId
    const absolutePath = generatePath(path, { integrationId });
    navigate(absolutePath);
  };

  const proceedToStep = (stepNumber: number) => {
    const stepIndex = stepNumber - 1;
    navigateWithIntegrationId(stepsPaths[stepIndex]);
  };

  const proceedToSuccess = () => {
    navigateWithIntegrationId(ROUTES.CONFIGURE_SUBSCRIPTION_SUCCESS);
  };

  return { proceedToStep, proceedToSuccess };
};
