import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiIcon, DidomiTreeCard } from '@didomi/ui-atoms-react';
import { ConfigTreePurposeWrapper, MappingOption } from '@types';
import { MappingField } from 'components/MappingField/MappingField';
import { MappingPreferencesTree } from 'components/MappingPreferencesTree/MappingPreferencesTree';

interface MappingPurposeCardProps {
  purposeWrapper: ConfigTreePurposeWrapper;
  mappingOptions: MappingOption[];
}

export const MappingPurposeCard = ({ purposeWrapper, mappingOptions }: MappingPurposeCardProps) => {
  return (
    <DidomiTreeCard
      isExpanded={false}
      headerText={getTranslatedValue(purposeWrapper.purpose.description)}
      headerIcon="purpose"
    >
      {/* Purpose Controls */}
      <div slot="header-controls">
        <MappingField name={purposeWrapper.id} mappingOptions={mappingOptions} />
      </div>

      {/* Preferences tree of the purpose */}
      {purposeWrapper.selected_preferences.length > 0 && (
        <MappingPreferencesTree
          preferences={purposeWrapper.selected_preferences}
          mappingOptions={mappingOptions}
        />
      )}

      {/* Empty state - purpose does not have preferences */}
      {purposeWrapper.selected_preferences.length === 0 && (
        <div className="flex gap-2 items-center pt-xs">
          <DidomiIcon name="preference" />
          No preference added yet
        </div>
      )}
    </DidomiTreeCard>
  );
};
