import React, { useMemo, useState } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Integration, IntegrationsFilters } from '@types';
import { getIntegrationsFilteringOptions } from '@utils';

const INITIAL_FILTERS = {
  purchaseType: null,
  compatibilities: [],
  categories: [],
  subscriptionType: null,
};

interface IntegrationsListToolbarProps {
  className?: string;
  numberOfResults: number;
  integrations: Integration[];
  onSearchChange: (searchValue: string) => void;
  onFiltersChange: (filters: IntegrationsFilters) => void;
}

export const IntegrationsListToolbar = ({
  className,
  numberOfResults,
  integrations,
  onSearchChange,
  onFiltersChange,
}: IntegrationsListToolbarProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filtersValues, setFilterValues] = useState<IntegrationsFilters>(INITIAL_FILTERS);

  const filteringOptions = useMemo(
    () => getIntegrationsFilteringOptions(integrations),
    [integrations],
  );

  const filters = useMemo(
    () => ({
      purchaseType: {
        placeholder: 'All prices',
        value: INITIAL_FILTERS.purchaseType,
        options: filteringOptions.purchaseType,
      },
      compatibilities: {
        placeholder: 'Compatibility',
        multi: true,
        value: INITIAL_FILTERS.compatibilities,
        options: filteringOptions.compatibilities,
      },
      categories: {
        placeholder: 'Categories',
        multi: true,
        value: INITIAL_FILTERS.categories,
        options: filteringOptions.categories,
      },
      subscriptionType: {
        placeholder: 'Type',
        value: INITIAL_FILTERS.subscriptionType,
        options: filteringOptions.subscriptionType,
      },
    }),
    [filteringOptions],
  );

  const handleFilterChange = e => {
    const filterToSet = e.detail as { key: string; newValue: string };
    const updatedFilters = { ...filtersValues, [filterToSet.key]: filterToSet.newValue };

    setFilterValues(updatedFilters);
    onFiltersChange(updatedFilters);
  };

  const handleSearchChange = e => {
    setSearchValue(e.detail);
    onSearchChange(e.detail);
  };

  const handleClearAllFilters = () => {
    setSearchValue('');
    setFilterValues(INITIAL_FILTERS);

    onSearchChange('');
    onFiltersChange(INITIAL_FILTERS);
  };

  return (
    <DidomiFiltersBar
      className={tx('block', className)}
      data-cy="filters-bar"
      searchValue={searchValue}
      showSearch
      leftText={getCounterText(numberOfResults, 'result')}
      collapsibleFilters={false}
      filters={filters}
      onSearchTextChange={handleSearchChange}
      onFilterValueChange={handleFilterChange}
      onClearAllFilters={handleClearAllFilters}
    />
  );
};
