import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { SPA_FULL_NAME } from '@constants';

type PreBookingModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const PreBookingModal = ({ isOpen, onConfirm, onCancel }: PreBookingModalProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);

  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} variant="slim" closable openChange={handleOnChange}>
      <img src={`${ASSETS_URL}/assets/dots-grid.svg`} alt="" className={tx('block')} />
      <DidomiModalHeader
        modalTitle="Please book a demo to enable the module"
        modalDescription="Our customer Success team will manually enable the feature for your organization during the demo and will show you how to use it."
      />

      <DidomiModalActions>
        <DidomiButton
          iconRight="calendar"
          data-cy="modal-primary-action"
          data-tracking="marketplace-subscribe-book-demo-button"
          onClick={onConfirm}
        >
          Book a demo
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
