import React from 'react';
import { DidomiButton, DidomiPromotionalPush } from '@didomi/ui-atoms-react';

interface IntegrationPromoPushProps {
  pushTitle: string;
  pushImage: string;
  onBookDemoClicked: () => void;
}

export const IntegrationPromoPush = ({
  pushImage,
  pushTitle,
  onBookDemoClicked,
}: IntegrationPromoPushProps) => (
  <DidomiPromotionalPush variant="light" data-cy="integration-promo">
    {/* Image */}
    <img slot="img" width="144" src={pushImage} alt="" />

    {/* Title */}
    <div slot="title">{pushTitle}</div>

    {/* Description */}
    <div slot="description">Get in touch with us to enable this integration.</div>

    {/* CTA */}
    <DidomiButton
      slot="cta"
      variant="secondary-light"
      size="small"
      data-cy="integration-promo-cta"
      data-tracking="marketplace-book-demo-button"
      onClick={onBookDemoClicked}
    >
      Book a demo
    </DidomiButton>
  </DidomiPromotionalPush>
);
