import { useMemo } from 'react';
import { matchId } from '@didomi/helpers';
import { getOptionsFormFields, getSettingsFormFields } from '@utils';
import { useSubscriptionsApi } from './useSubscriptionsApi.hook';

export const useSubscriptionSetup = (integrationId: string, selectedTypeId?: string) => {
  const api = useSubscriptionsApi(integrationId);

  // UI is using the first subscription config that was added
  const subscriptionConfig = api.subscriptionConfigs?.[0];

  // Config type is either the selected one or the first one by default
  const subscriptionConfigType = useMemo(() => {
    const typeId = selectedTypeId || subscriptionConfig?.type_id;
    if (typeId) {
      return api.subscriptionConfigTypes?.find(matchId(typeId));
    } else {
      return api.subscriptionConfigTypes?.[0];
    }
  }, [api.subscriptionConfigTypes, selectedTypeId, subscriptionConfig]);

  // Fields to display on the settings tab
  const settingsFormFields = useMemo(() => {
    if (subscriptionConfigType) {
      return getSettingsFormFields(subscriptionConfigType, subscriptionConfig);
    }
  }, [subscriptionConfigType, subscriptionConfig]);

  // Fields to display on the settings tab
  const optionsFormFields = useMemo(() => {
    if (subscriptionConfigType) {
      return getOptionsFormFields(subscriptionConfigType, subscriptionConfig);
    }
  }, [subscriptionConfigType, subscriptionConfig]);

  const isWithMapping = subscriptionConfigType?.collect_mappings;
  const isWithOptions = optionsFormFields?.length > 0;

  return {
    subscriptionConfig,
    subscriptionConfigType,
    settingsFormFields,
    optionsFormFields,
    isWithMapping,
    isWithOptions,
  };
};
