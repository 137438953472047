import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { useFeatureFlag, useSPARouter } from '@didomi/utility-react';
import {
  IntegrationDetailsHeader,
  IntegrationFullInfo,
  IntegrationInfoBanner,
  Loader,
} from '@components';
import { SPA_FULL_NAME } from '@constants';
import { useIntegrationsApi } from '@hooks';
import { ROUTES } from '@utils';

export const IntegrationDetails = () => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const { integrationId } = useParams();
  const { integration, isLoading } = useIntegrationsApi(integrationId);

  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();

  const [isIntegrationEnabled] = useFeatureFlag(integrationId as any);
  const [isPmpEnabled] = useFeatureFlag('preference_management_platform');

  const navigateToManage = () => {
    if (integration.feature_url_path) {
      // Navigate to the SPA module responsible for current integration
      navigateTo(integration.feature_url_path);
    } else {
      // Navigate to the subscription settings page
      navigate(ROUTES.CONFIGURE_SUBSCRIPTION.replace(':integrationId', integrationId));
    }
  };

  return (
    <section
      className={`p-l pb-20 text-primary-blue-6 bg-repeat-y bg-[url(${ASSETS_URL}/assets/details-pattern.png)] bg-right min-h-full`}
    >
      {integration && (
        <>
          <IntegrationDetailsHeader
            logo={`${ASSETS_URL}/assets/${integration.short_details.logo_url}`}
            isIntegrationEnabled={isIntegrationEnabled}
            integrationCta={integration.cta}
            onBackClick={() => navigate(ROUTES.EXPLORE)}
            onNavigateToManageClick={navigateToManage}
          />

          <div className="flex-grow flex justify-between gap-m mt-14">
            <IntegrationFullInfo
              className="w-[400px] lg:w-[460px] 2xl:w-[660px]"
              integration={integration}
            />
            <IntegrationInfoBanner
              className="flex-1 max-w-[570px] xl:max-w-[735px]"
              integration={integration}
              isIntegrationEnabled={isIntegrationEnabled}
              isPmpEnabled={isPmpEnabled}
            />
          </div>
        </>
      )}

      {isLoading && <Loader>Loading integration</Loader>}
    </section>
  );
};
