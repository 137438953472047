import { useParams } from 'react-router-dom';
import React from 'react';
import { DidomiButton, DidomiEmptyState, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { Form, Formik } from 'formik';
import {
  useSubscriptionMappingState,
  useSubscriptionsApi,
  useSubscriptionSetup,
  useSubscriptionSetupNavigation,
} from '@hooks';
import { BlockNavigationModal, LoadingModal } from '@modals';
import { transformMappingFormToPayload } from '@utils';
import { MappingPurposeCard } from 'components/MappingPurposeCard/MappingPurposeCard';

export const ConfigureSubscriptionMappings = () => {
  const { proceedToSuccess } = useSubscriptionSetupNavigation();
  const { integrationId } = useParams();
  const { selectedPurposes, initialValues, validationSchema, mappingOptions, isLoading } =
    useSubscriptionMappingState(integrationId);

  const { isUpdating, updateConfig } = useSubscriptionsApi(integrationId);
  const { subscriptionConfig } = useSubscriptionSetup(integrationId);

  const handleSaveMapping = (formValues: Record<string, string>, proceedNavCb?: () => void) => {
    const payload = {
      type_id: subscriptionConfig.type_id,
      mappings: transformMappingFormToPayload(formValues),
      enabled: true,
    };
    // After save success, navigate to the success page or proceed with the router blocking navigation
    const onSuccess = () => (proceedNavCb ? proceedNavCb() : proceedToSuccess());

    updateConfig({ configId: subscriptionConfig.id, config: payload }, { onSuccess });
  };

  return (
    <div>
      {selectedPurposes.length > 0 && !isLoading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={values => handleSaveMapping(values)}
        >
          {({ isValid, dirty, values }) => (
            <Form>
              <fieldset className="mx-auto max-w-[800px] flex flex-col gap-xxs">
                {selectedPurposes.map(p => (
                  <MappingPurposeCard
                    key={p.id}
                    purposeWrapper={p}
                    mappingOptions={mappingOptions}
                  />
                ))}
              </fieldset>

              {/* Form Actions */}
              <div className="fixed bottom-0 flex gap-xs justify-center w-full -mx-l p-xs bg-white border-solid border-t-1 border-neutral-gray-3">
                <DidomiButton type="submit" disabled={!isValid || !dirty}>
                  Save mapping
                </DidomiButton>
              </div>

              <BlockNavigationModal
                isBlocked={dirty && !isUpdating}
                isSaveAllowed={isValid}
                onSaveAndProceedClicked={proceedNavCb => handleSaveMapping(values, proceedNavCb)}
              />
            </Form>
          )}
        </Formik>
      )}

      {/* Empty State */}
      {selectedPurposes.length === 0 && !isLoading && (
        <DidomiEmptyState illustrationName="no-purposes-yet">
          No purposes were found
        </DidomiEmptyState>
      )}

      {/* Initial Loading State */}
      {isLoading && (
        <DidomiSkeleton className="block mx-auto max-w-[800px]" variant="layout" isLoading={true}>
          <div className="mb-xxs w-full h-[66px]" data-skeleton></div>
          <div className="mb-xxs w-full h-[66px]" data-skeleton></div>
          <div className="mb-xxs w-full h-[66px]" data-skeleton></div>
          <div className="mb-xxs w-full h-[66px]" data-skeleton></div>
          <div className="mb-xxs w-full h-[66px]" data-skeleton></div>
        </DidomiSkeleton>
      )}

      {/* Modals */}
      <LoadingModal isOpen={isUpdating} title="Saving changes..." />
    </div>
  );
};
