import { Integration, PurchaseType, SubscriptionType } from '@types';

const PURCHASE_TYPE_OPTIONS = [
  { value: null, label: 'View all' },
  { value: PurchaseType.FREE, label: 'Free' },
  { value: PurchaseType.PAID, label: 'Purchase' },
];

const SUBSCRIPTION_TYPE_OPTIONS = [
  { value: null, label: 'View all' },
  { value: SubscriptionType.PREMIUM_FEATURE, label: 'Premium feature' },
  { value: SubscriptionType.CONNECTOR, label: 'Connector' },
];

// Helpers
const valueToOption = (value: string) => ({ value, label: value });
const sortAtoZ = (a: string, b: string) => (a < b ? -1 : 1);

// Extract filtering options from integrations
export const getIntegrationsFilteringOptions = (integrations: Integration[] = []) => {
  const dynamicOptions = integrations.reduce(
    (acc, integration) => {
      integration.compatibilities.forEach(compatibility => acc.compatibilities.add(compatibility));
      integration.tags.forEach(category => acc.categories.add(category));
      return acc;
    },
    {
      compatibilities: new Set<string>(),
      categories: new Set<string>(),
    },
  );

  return {
    purchaseType: PURCHASE_TYPE_OPTIONS,
    subscriptionType: SUBSCRIPTION_TYPE_OPTIONS,
    compatibilities: Array.from(dynamicOptions.compatibilities).sort(sortAtoZ).map(valueToOption),
    categories: Array.from(dynamicOptions.categories).sort(sortAtoZ).map(valueToOption),
  };
};
