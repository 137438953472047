import * as Yup from 'yup';
import { MAPPING_FIELD_PATTERN } from '@constants';

// Generate Yup validation schema based on the initial values - add validation for each key
export const getMappingFormValidationSchema = (initialValues: Record<string, string>) => {
  const ERROR_TEXT = 'Supported characters: alphanumeric, underscore, space.';

  // Add each key to the shape with the same validation schema
  const shape = Object.keys(initialValues).reduce((acc, key) => {
    acc[key] = Yup.string().matches(MAPPING_FIELD_PATTERN, ERROR_TEXT).nullable();
    return acc;
  }, {});

  return Yup.object().shape(shape);
};
