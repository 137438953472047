/* eslint-disable max-len */
// Integrations Data for Beta(to be updated)

import { Integration } from '@types';

export const INTEGRATIONS_DATA: Integration[] = [
  /* Premium features */
  {
    id: 'cross_device',
    type_ids: [],
    template_id: 'didomi_subscription',
    name: 'Cross Device',
    is_draft: false,
    is_free: false,
    pricing: 200,
    feature_url_path: 'consent-notices',
    short_details: {
      logo_url: 'mvp-data-assets/logos/cross_device.png',
      description: 'Share your users’ consent across devices and environments with ease',
    },
    long_details: {
      headline: 'Share your users’ consent across devices and environments with ease',
      long_description:
        '<p>Cross device feature enables users to share consent across different platforms, such as web, mobile and Connected TV. Your users&rsquo; consent on one device is preserved for the next time they connect with another device.</p><ul><li>By asking for consent less frequently, Cross device enhances your users&rsquo; experience</li></ul><ul><li>Through shared consent choices and a higher positive consent rate, Cross device helps you boost your revenue</li></ul><p>The functionality is activated with just two clicks in your Didomi Console.</p>',
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/en/cross-device',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/share-consents-across-devices',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/how-to-enable-cross-device-from-didomi-console',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/cross_device_banner.png',
        },
      ],
      prerequisites: [],
    },
    compatibilities: ['CMP'],
    tags: [],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-cross-device-introduction',
  },
  {
    id: 'ctv',
    type_ids: [],
    template_id: 'didomi_subscription',
    name: 'CTV',
    is_draft: false,
    is_free: false,
    pricing: 250,
    feature_url_path: 'consent-notices',
    short_details: {
      logo_url: 'mvp-data-assets/logos/ctv.png',
      description: 'Didomi’s consent management SDK solution for connected TV and OTT platforms',
    },
    long_details: {
      headline: 'Didomi’s consent management SDK solution for connected TV and OTT platforms',
      long_description:
        '<p>Collect and store user consent on Connected TV (CTV) platforms and OTT applications. Synchronize the data across all your platforms to provide a seamless user experience while respecting their choices.</p><p>Make the most of the ability to design a tailored banner that adheres to your branding guidelines and deploy consent banners in the language of your choice.</p><p>Android TV, Apple TV, and Amazon Fire TV are all supported. We will be supporting WebOS, Tizen, and many other platforms very soon!</p>',
      documentation_links: [
        {
          title: 'Blog',
          url: ' https://blog.didomi.io/en/didomi-connected-tv',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/mobile-sdk',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/create-a-consent-notice-for-your-ctv',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/ctv_banner_1.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/ctv_banner_2.png',
        },
      ],
      prerequisites: [],
    },
    compatibilities: ['CMP'],
    tags: [],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-ctv-introduction',
  },
  {
    id: 'sso',
    type_ids: [],
    template_id: 'didomi_subscription',
    name: 'Single Sign-On',
    is_draft: false,
    is_free: false,
    pricing: 100,
    feature_url_path: 'organization/sso',
    short_details: {
      logo_url: 'mvp-data-assets/logos/sso.png',
      description: 'Enable a secured & simplified access management to Didomi’s Console',
    },
    long_details: {
      headline: 'Enable a secured & simplified access management to Didomi’s Console',
      long_description:
        '<p>Didomi supports SSO based on SAML 2.0 standard protocol.</p><p>The feature allows users of an organization to sign on to the Didomi Console using their own identity providers like Google, Azure Active Directory, Okta, One Login and many more. It also helps organizations to maintain a strong security standard and a secure means to access Didomi’s Console based on a list of authorized domains.</p><p>If needed, one can activate a Multi-Factor Authentication (MFA) option to access Didomi’s Console by enabling it at the identity provider level.</p>',
      documentation_links: [
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/login-security',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_1.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_2.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_3.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_4.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_5.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_6.png',
        },
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sso_banner_7.png',
        },
      ],
      prerequisites: [],
    },
    compatibilities: [],
    tags: [],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-sso-introduction',
  },
  {
    id: 'batch_exports',
    type_ids: ['batch-exports-gcp', 'batch-exports-s3'],
    template_id: 'didomi_subscription',
    name: 'Batch Export',
    is_draft: false,
    is_free: false,
    pricing: 75,
    short_details: {
      logo_url: 'mvp-data-assets/logos/batch_export.png',
      description: 'The flexible way to export consent data collected by Didomi',
    },
    long_details: {
      website_link: null,
      headline:
        'The scalable way to export and access user consent data while leveraging business use cases',
      long_description: `
        <p>With the Batch Export feature, you are able to access all consent data collected through the Didomi platform (consent notices, APIs, Preference Center) at once - by pushing all user choices at the same time, placing them in storage buckets, AWS or GCP.</p>
        <p>Batch Export is the way to go in terms of scalability and promotes no risk of loss of data. It introduces a flexible and scalable way to export and access user consent data. In turn, this opens up new potentialities for leveraging user consent data for business use cases like analytics, data science, or simply on-premise backups.</p>
        <p>Ultimately, Batch Export changes the game in terms of data mapping and visualisation.</p>
      `,
      prerequisites: ['Have AWS account or a GCP bucket configured'],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/en/batch-export',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/understand-batch-export-content',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/batch-export',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/batch_export_banner.png',
        },
        {
          type: 'video',
          url: 'mvp-data-assets/carousel/videos/batch_export_hd.mp4',
        },
      ],
    },
    compatibilities: ['PMP', 'CMP'],
    tags: ['Generic'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-batch-export',
  },
  {
    id: 'batch_exports_analytics',
    type_ids: ['analytics-exports-gcp', 'analytics-exports-s3'],
    template_id: 'didomi_subscription',
    name: 'Analytics Export',
    is_draft: false,
    is_free: false,
    pricing: 50,
    short_details: {
      logo_url: 'mvp-data-assets/logos/analytics_export.png',
      description: 'The scalable way to export analytics data',
    },
    long_details: {
      headline:
        'The scalable way to export and access analytics data while leveraging business use cases.',
      long_description:
        '<p>With the Analytics Export feature, you are able to receive automated export of the analytics data. By doing so, it allows you to integrate your data in existing dashboards, store data for the period you want or to create reports adapted to your custom needs.</p>\n<p>It introduces a flexible and scalable way to export and access analytics data. In turn, this opens up new possibilities for leveraging analytics data for business use cases.</p>\n<p>Available destinations: AWS S3, Google Cloud Storage.</p>',
      prerequisites: ['Have AWS account or a GCP bucket configured', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/understand-analytics-data-export-files?hs_preview=pcODOwAS-66587014490',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/generic-integrations/analytics-export',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/analytics_export_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Analytics'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-analytics-export-introduction',
  },
  {
    id: 'webhooks',
    type_ids: ['webhook-http', 'webhook-aws-sqs'],
    template_id: 'didomi_subscription',
    name: 'Webhook',
    is_draft: false,
    is_free: false,
    pricing: 75,
    short_details: {
      logo_url: 'mvp-data-assets/logos/webhook.png',
      description: 'Easily stream user choices in real time',
    },
    long_details: {
      website_link: null,
      headline:
        'Webhooks pushes data in real time, making data sharing easier while saving you time',
      long_description: `
          <p>Webhooks push data in real time. Making data sharing easier, while saving you time, as you do not need to pull the data yourself. You can have it pushed directly to your third-party vendors that need it for business operations (marketing campaigns, analytics, etc.).</p>
          <p>To configure this integration and to authenticate on your server, you will need to provide 'Client id', 'secret' and the URL of the service (where you want us to stream the user choices).</p>
          <p>Save time and resources with Webhooks by having the data pushed directly to your services, as soon as it's available. </p>
      `,
      prerequisites: [
        'API endpoint of the server receiving the webhooks.',
        'Auth protocol should be OAuth 2.0',
        'You must whitelist IP 35.159.1.63 to allow traffic from it',
      ],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/en/didomi-integrations-data-transfer-methods',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/webhooks',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/webhook_banner.png',
        },
      ],
    },
    compatibilities: ['PMP', 'CMP'],
    tags: ['Generic'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-webhook-introduction',
  },

  /* V2 integration */
  {
    id: 'ab_tasty',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'AB Tasty',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/ab_tasty.png',
      description: 'User consent for your AB Tasty tags',
    },
    cta: {
      text: 'Go to AB Tasty',
      link: 'https://app2.abtasty.com/login',
    },
    long_details: {
      website_link: 'https://www.abtasty.com/',
      headline: 'Introduce consent to your AB Tasty tags with this CMP integration',
      long_description: `<p>AB Tasty is a platform that helps you optimize user experience and improve engagement through personalization, experimentation and feature management.</p><p>If you are an AB Tasty user, you can now use our AB Tasty integration, which allows Didomi to send consent information to AB Tasty so that tags are loaded only after a user has given consent.</p><p>You can use AB Tasty as a native integration or use Didomi to block AB Tasty's tags before consent. </p>`,
      prerequisites: ['Have an AB Tasty account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/set-up-the-ab-tasty-integration-with-didomi',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/ab_tasty_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['AB testing'],
  },

  {
    id: 'actito',
    type_ids: ['actito'],
    template_id: 'didomi_subscription',
    name: 'Actito',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/actito.png',
      description: 'Activate your Actito campaigns, based on user choices',
    },
    long_details: {
      website_link: 'https://www.actito.com/',
      headline:
        'Leverage user preferences by sharing them with Actito, to deliver personalised marketing campaigns',
      long_description: `
          <p>Leverage user preferences in your Actito marketing campaigns and stream data in real time. The preferences collected, via Didomi, will be shared with Actito, so you can seamlessly adjust your marketing campaigns based on user choices. Ultimately, Didomi's consent events data will be mapped to Actito subscription profiles.</p>
          <p>To enable the Actito integration, you will have to provide:</p>
          <ul>
            <li>API key of the account to connect to Actito's servers</li>
            <li>ID of the Actito entity</li>
            <li>Name of the Actito table, where profiles are stored</li>
            <li>List of subscription properties to match between Didomi preferences and Actito</li>
          </ul>
        `,
      prerequisites: [
        'Have an Actito account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/actito',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/actito_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-actito-introduction',
  },
  {
    id: 'adobe_campaign_classic',
    type_ids: ['adobe-campaign-classic'],
    template_id: 'didomi_subscription',
    name: 'Adobe Campaign',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/adobe_campaign.png',
      description: 'Deliver Adobe Campaigns, based on user preferences',
    },
    long_details: {
      website_link: 'https://www.business.adobe.com/fr/products/campaign/adobe-campaign.html',
      headline:
        'Send user preference data to Adobe Campaign seamlessly, to leverage personalised campaigns',
      long_description: `
          <p>The Adobe Campaign integration is based on Classic Campaign API's. It will allow you to transfer every user choice collected by Didomi, in real time. Enabling you to adjust your campaigns easily, according to user preferences.</p>
          <p>If you want to configure the Adobe Campaign integration, you will have to provide:</p>
          <ul>
            <li>Your Adobe Campaign user & password</li>
            <li>The URL of the Adobe Campaign server</li>
            <li>The folder ID where your profiles are updated</li>
            <li>List of services to match between Didomi preferences and Adobe Campaign</li>
          </ul>
        `,
      prerequisites: [
        'Have an Adobe Campaign account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/adobe-campaign-classic',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/adobe_campaign_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-adobe-introduction',
  },

  /* V2 integration */
  {
    id: 'adobe_dtm_launch',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Adobe DTM & launch',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/adobe_launch.png',
      description: 'Improve Adobe DTM & Launch tags with consent data',
    },
    cta: {
      text: 'Go to Adobe Launch',
      link: 'https://launch.adobe.com/',
    },
    long_details: {
      website_link: 'https://business.adobe.com/',
      headline: 'Integrate Didomi with Adobe DTM & Launch to optimize tag management',
      long_description: `<p>Adobe Dynamic Tag Management (DTM) & Adobe Launch allow marketers to manage tags quickly and efficiently.</p><p>Integrating Adobe Launch & DTM with Didomi's Consent Management Platform (CMP) helps managing your website's tags and communicating consent information to Adobe to trigger tag calls. The consent sharing is done in one-clock from the Integrations section of your notice.</p>`,
      prerequisites: ['Have an Adobe DTM or Launch account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/adobe-launch-dtm ',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/adobe_launch_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Tag manager'],
  },

  /* INT-519 Adobe Experience Cloud */
  {
    id: 'adobe_experience_cloud',
    type_ids: ['adobe-experience-cloud'],
    template_id: 'didomi_subscription',
    name: 'Adobe Experience Cloud',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/adobe_experience_cloud.png',
      description:
        'Expertly manage the consent journey so all Adobe products work perfectly across your business.',
    },
    long_details: {
      website_link: 'https://experience.adobe.com',
      headline:
        'Expertly manage the consent journey so all Adobe products work perfectly across your business.',
      long_description: `<p>Leverage native integration workflows to sync user preferences to your Adobe CDP and ensure alignment with different Adobe products.</p>`,
      prerequisites: [
        'Adobe Experience Cloud',
        'Didomi Preference Center configured',
        'Users identified by unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/adobe-experience-cloud#implementation',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/adobe-experience-cloud-cdp-integration',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/adobe_experience_cloud_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['CDP'],
  },

  /* INT-561 */
  {
    id: 'marketo',
    type_ids: ['marketo'],
    template_id: 'didomi_subscription',
    name: 'Adobe Marketo Engage',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/marketo.png',
      description: `Improve user enagagement by sync'ing user preferences with Marketo`,
    },
    long_details: {
      website_link: 'https://www.marketo.com/',
      headline: `Improve user enagagement by sync'ing user preferences with Marketo`,
      long_description: `<p>Marketo is the best-in-class enterprise marketing automation software, owned by Adobe.</p><p>Integrating Didomi's PMP and Marketo lets you leverage rich zero- and first-party data collected with Didomi's PMP in your marketing campaigns. Use the integration now to understand your audience and orchestrate the best buyer journey.</p>`,
      prerequisites: [
        'Have a Marketo Engage account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/adobe-marketo-engage',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/marketo_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-marketo-introduction',
  },

  /* V2 integration */
  {
    id: 'at_internet',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'AT Internet',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/at_internet.png',
      description: 'Combine consent and data analysis with AT Internet',
    },
    cta: {
      text: 'Go to AT Internet',
      link: 'https://apps.atinternet-solutions.com/login/',
    },
    long_details: {
      website_link: 'https://www.atinternet.com/',
      headline: 'Run your data analysis with AT Internet while complying with privacy regulations',
      long_description: `<p>AT Internet is a web analytics platform allowing you to measure and analyse traffic in accordance with privacy regulations.</p><p>AT Internet relies on three different modes: opt-in, opt-out and exemption (CNIL exemption). Integrating AT Internet and Didomi will allow you to implement web analytics tools that are compliant with the French DPA's recommendations.</p>`,
      prerequisites: ['Have an AT Internet account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/at-internet',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/set-up-at-internet-with-didomi',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/at_internet_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Analytics'],
  },

  /* Braze */
  {
    id: 'braze',
    type_ids: ['braze'],
    template_id: 'didomi_subscription',
    name: 'Braze',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/braze.png',
      description:
        'Deliver personalized content to your audience by capturing relevant zero- and first-party data',
    },
    long_details: {
      website_link: 'https://www.braze.com/',
      headline:
        'Deliver personalized content to your audience by capturing relevant zero- and first-party data',
      long_description: `<p>Braze is a leading customer engagement platform that enables brands and consumers to connect and interact in real time.&nbsp;</p>
      <p>Didomi&apos;s Preference Management Platform (PMP) integration with Braze enables brands to seamlessly and instantaneously synchronize consumer preference data using the PMP to the right contacts in Braze&apos;s contact base.&nbsp;</p>
      <p>Collect pertinent user preferences to display personalized content and increase customer engagement.&nbsp;</p>`,
      prerequisites: [
        'Have a Braze account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/braze',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/braze_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-braze-introduction',
  },

  /* V2 integration */
  {
    id: 'commanders_act',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'CommandersAct',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/commanders.png',
      description: 'Leverage consent data in your CommandersAct tags',
    },
    cta: {
      text: 'Go to CommandersAct',
      link: 'https://platform.commandersact.com/',
    },
    long_details: {
      website_link: 'https://www.commandersact.com/',
      headline: 'Combine consent data from your CMP with your CommandersAct tags',
      long_description:
        '<p>Integrate CommandersAct with Didomi to use consent on your website tags.</p><p>Didomi sends consent data as a variable in CommandersAct events. This integration relies on the creation of rules applicable to vendor tags that do not support the IAB framework.</p><p>You can seamlessly share consent from the Integrations section of your notice.</p>',
      prerequisites: ['Have a CommandersAct account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Reach out to support@didomi.io for documentation.',
          url: 'mailto:support@didomi.io?subject=Marketplace:%20CommandersAct%20Documentation',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/commanders_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Tag manager'],
  },

  /* V2 integration */
  {
    id: 'eulerian',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Eulerian',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/eulerian.png',
      description: 'Eulerian x Didomi, when Big Data meets consent ',
    },
    cta: {
      text: 'Go to Eulerian',
      link: 'https://pm.ea.eulerian.com/servicedesk/customer/user/login?destination=portals',
    },
    long_details: {
      website_link: 'https://www.eulerian.com/',
      headline: 'Integrate Eulerian and Didomi to target calls based on user consent',
      long_description:
        '<p>Eulerian is an augmented analytics platform combining attribution, analytics, and activation to help you make the best marketing decisions quickly.</p><p>Integrating Eulerian and Didomi allows you to target calls based on your users\' consent data. To activate the integration, activate the slave mode in the Console, enable the Eulerian integration from the "Integrations" section of your notice, then add the script and create the categories. Link the categories and activate the slave mode, and you\'re done.</p>',
      prerequisites: ['Have an Eulerian account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/eulerian ',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/eulerian_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Tag manager'],
  },

  /* V2 integration */
  {
    id: 'google_ad_manager',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Google Ad Manager',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/google_ad_manager.png',
      description: 'Share consent notice data with Google Ad Manager',
    },
    cta: {
      text: 'Go to Google Ad Manager',
      link: 'https://ads.google.com/',
    },
    long_details: {
      website_link: 'https://ads.google.com/',
      headline: 'Customize ultra-personalized ads with Didomi consent data for Google Ad Manager',
      long_description: `<p>Google Ad Manager is an ad management platform for large publishers.</p><p>Didomi's integration with Google Ad Manager will help you use the consent collected with Didomi in your Google Ad Manager campaigns. Note that Didomi also supports Google Ad Manager Additional Consent mode.</p><p>You can easily enable consent sharing from the Integrations section of your notice.</p>`,
      prerequisites: ['Have a Google Ad Manager account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/google-dfp-adsense-adx ',
        },
        {
          title: 'Google Technical documentation',
          url: 'https://support.google.com/admanager/answer/9681920?hl=en',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/google_ad_manager_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Advertising'],
  },

  /* V2 integration */
  {
    id: 'google_consent_mode',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Google Consent mode',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/google_consent.png',
      description: 'Put user choice at the heart of your conversion data',
    },
    cta: {
      text: 'Go to Google Consent mode',
      link: 'https://support.google.com/analytics/answer/9976101',
    },
    long_details: {
      website_link: 'https://analytics.google.com/',
      headline: 'Install Google Consent Mode integration to leverage your conversion data',
      long_description: `<p>Google Consent Mode (GCM) serves as a link between data privacy and digital advertising by adjusting the behavior of Google tags to the consent status of your users.</p><p>The integration between GCM and the Didomi Consent Management Platform (CMP) allows for greater insights on your conversion data while respecting user's consent. Sharing the CMP consent data with Google Consent Mode tags will make it possible to deliver personalized ads, as tags will behave following the consent of your users, and provide you with an improved user experience.</p><p>You can easily enable Google Consent Mode from the Integrations section of your notice.</p>`,
      prerequisites: [
        'Have a Google Ads, Floodlight and/or Google Analytics account',
        'Have a CMP configured',
      ],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/en/didomi-google-consent-mode ',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/fr/activer-google-consent-mode-avec-didomi',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/google-consent-mode#google-consent-mode-purposes-statuses',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/google_consent_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Advertising'],
  },

  /* V2 integration */
  {
    id: 'google_tag_manager',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Google Tag Manager',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/gtm.png',
      description: 'Add consent data to Google Tag Manager',
    },
    cta: {
      text: 'Go to Google Tag Manager',
      link: 'https://tagmanager.google.com/',
    },
    long_details: {
      website_link: 'https://tagmanager.google.com/',
      headline: 'Improve your data layers with the Didomi and GTM integration',
      long_description:
        '<p>Google Tag Manager (GTM) is a solution that controls the tags embedded in your web platforms.</p><p>The GTM and Didomi integration allows you to leverage user consent information to your tags in GTM. This information transfer acts as events and variables. You can easily integrate Google Tag Manager from the Integrations section of your notice.</p>',
      prerequisites: ['Have a Google Tag Manager account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/google-tag-manager',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/gtm_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Tag manager'],
  },

  /* INT- 812 Dotdigital*/
  {
    id: 'dotdigital', //premium feature id
    type_ids: ['dotdigital'], //integratiin type
    template_id: 'didomi_subscription',
    name: 'Dotdigital',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/dotdigital.svg',
      description: `Create customized marketing experiences with the Didomi-Dotdigital integration`,
    },
    long_details: {
      website_link: 'https://dotdigital.com',
      headline: `Create customized marketing experiences with the Didomi-Dotdigital integration`,
      long_description: `<p>Streamline user preferences from Didomi's PMP to Dotdigital and build a solid marketing experience.</p>`,
      prerequisites: [
        'Dotdigital account',
        'Didomi Preference Center configured',
        'Users identified by unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/dotdigital',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/dotdigital_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
  },

  {
    id: 'hubspot',
    type_ids: ['hubspot'],
    template_id: 'didomi_subscription',
    name: 'Hubspot',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/hubspot.png',
      description: 'Leverage your campaigns with Hubspot based on user preferences',
    },
    long_details: {
      website_link: 'https://www.hubspot.com/',
      headline: 'Easily share consent and preferences with Hubspot to deliver targeted campaigns',
      long_description: `
        <p>The preferences collected, via Didomi, will be shared with Hubspot, so you can leverage your marketing campaigns based on user choices. The data transfer can also, with the correct configuration, flow from Hubspot to Didomi.</p>
        <p>To enable the Hubspot integration, you will have to connect to the Didomi app with your Hubspot credentials, and provide the mapping between Hubspot and Didomi fields.</p>
      `,
      prerequisites: [
        'Have a Hubspot account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/hubspot',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/how-to-configure-didomi-hubspot-integration',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/hubspot_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-hubspot-introduction',
  },

  /* V2 integration */
  {
    id: 'kameleoon',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Kameleoon',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/kameleoon.png',
      description: 'Create consent-based user experiences',
    },
    cta: {
      text: 'Go to Kameleoon',
      link: 'https://login.kameleoon.com/app',
    },
    long_details: {
      website_link: 'https://www.kameleoon.com/',
      headline: 'Supercharge your Kameleoon A/B tests with Didomi consent notices',
      long_description: `<p>Kameleoon is an IA personalization technology platform for real-time omnichannel optimization and conversion.</p><p>Integrating Kameleoon with Didomi gives the possibility to run A/B testing campaigns based on user choices. This will allow companies to learn from their customers' behavior and personalize offers, interactions, navigation, content and messages on all channels, while staying compliant with privacy regulations.</p>`,
      prerequisites: ['Have a Kameleoon account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/fr/didomi-x-kameleoon',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/kameleoon',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/set-up-the-kameleoon-integration-with-didomi',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/kameleoon_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['AB testing'],
  },

  /* New - INT-324 */
  {
    id: 'mailchimp',
    type_ids: ['mailchimp'],
    template_id: 'didomi_subscription',
    name: 'Mailchimp',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/mailchimp.png',
      description: 'Enrich your Mailchimp contacts with preference data for improved targeting',
    },
    long_details: {
      website_link: 'https://mailchimp.com/',
      headline: 'Enrich your Mailchimp contacts with preference data for improved targeting',
      long_description: `<p>Mailchimp is a marketing, automation and email platform.</p><p>Didomi's integration with Mailchimp offers the possibility to synchronize users' preferences collected with the PMP with your Mailchimp contacts. Didomi collects preferences and automatically transmits them to Mailchimp for a better targeting of your marketing campaigns and an optimized ROI.</p><p>All you need is your Mailchimp API Key, and Didomi handles the rest.</p>`,
      prerequisites: [
        'Have a Mailchimp account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Help center',
          url: 'https://support.didomi.io/how-to-synchronize-your-users-preferences-with-your-mailchimp-contacts',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/mailchimp',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/mailchimp_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-mailchimp-introduction',
  },

  /* INT-812 Microsoft Dynamics 365 */
  {
    id: 'microsoft_dynamics',
    type_ids: ['microsoft-dynamics'],
    template_id: 'didomi_subscription',
    name: 'Microsoft Dynamics 365',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/microsoft_dynamics.png',
      description: 'Leverage user preferences data across Microsoft Dynamics 365',
    },
    long_details: {
      website_link: 'https://dynamics.microsoft.com',
      headline: 'Leverage user preferences data across Microsoft Dynamics 365',
      long_description: `<p>Push and pull your contact preferences to Microsoft Dynamics CRM.</p>
        <p>Integrating your Didomi PMP with Microsoft Dynamics 365 allows you to leverage user preferences data in Microsoft's data platform, Dataverse. Data can then be used by Dynamics 365 apps such as Dynamics 365 Sales, Customer Service, Field Service, Marketing.</p>`,
      prerequisites: [
        'Microsoft Dynamics 365 account',
        'Didomi Preference Center configured',
        'Users identified by unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/microsoft-dynamics-365',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/microsoft_dynamics_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['CRM', 'Marketing automation'],
  },

  /* INT-561 */
  {
    id: 'mparticle',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'mParticle',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/mparticle.png',
      description: 'Leverage consent data in your mParticle CDP',
    },
    cta: {
      text: 'Go to mParticle',
      link: 'https://app.mparticle.com/',
    },
    long_details: {
      website_link: 'https://www.mparticle.com/',
      headline: 'Leverage consent data in your mParticle CDP',
      long_description: `<p>Integrate Didomi and mParticle to use consent information for all your integrations</p><p>The integration synchronizes user consent information, in real-time with mParticle's Data Privacy Controls to ensure consent-based triggering of your tools.</p>`,
      prerequisites: ['Have a mParticle account', 'Have a Consent Notice configured'],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/mparticle_banner.png',
        },
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/cmp-integrations/didomi-mparticle-integration-for-your-cmp',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['CDP'],
  },

  /* V2 integration */
  {
    id: 'poool',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Poool',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/poool.png',
      description: 'Gather consent and monetize your content with Poool',
    },
    cta: {
      text: 'Go to Poool',
      link: 'https://id.poool.fr/login',
    },
    long_details: {
      website_link: 'https://poool.fr/',
      headline: `Match your users' access to content with their consent choices thanks to Didomi and Poool`,
      long_description: `<p>Poool is a paywall and monetization platform.</p><p>Our Poool integration allows you to block specific content from users who have not given their consent. Integrating Didomi's CMP and Poool will help you target users' choices and offer them content that respects their consent preferences. Offer 100% customizable cookie walls by integrating Poool with the CMP.</p>`,
      prerequisites: ['Have a Poool account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/en/didomi-x-poool',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/set-up-a-cookie-wall-with-our-poool-integration',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/poool_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Paywall'],
  },

  /* V2 integration */
  {
    id: 'qiota',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Qiota',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/qiota.png',
      description: 'Boost your engagement rate and increase your revenue',
    },
    cta: {
      text: 'Go to Qiota',
      link: 'https://dash.qiota.com/',
    },
    long_details: {
      website_link: 'https://www.qiota.com/',
      headline: 'Integrate Didomi with Qiota for more consistent paywalls',
      long_description: `<p>Qiota is an advanced paywall management and content monetization SaaS platform.</p><p>Use Didomi's consent data to create unique user paths based on your visitors' choices, helping increase engagement with your content and placing your users' choices at the core of your paywall strategy.</p>`,
      prerequisites: ['Have a Qiota account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/use-qiota-integration-from-didomi-console ',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/qiota_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Paywall'],
  },

  /* V2 integration */
  {
    id: 'salesforce_dmp',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Salesforce DMP',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/salesforce_dmp.png',
      description: 'Synchronize your consent data with Salesforce DMP (Krux)',
    },
    cta: {
      text: 'Go to Salesforce DMP',
      link: 'https://console.krux.com/login',
    },
    long_details: {
      website_link: 'https://www.salesforce.com/',
      headline: 'Incorporate Didomi consent data into your Salesforce DMP (Krux) user experiences',
      long_description:
        '<p>Salesforce DMP is a tool that helps publishers and media companies in delivering unique advertising experiences to their users.</p><p>The integration between Didomi and Salesforce DMP (Krux) allows you to merge Didomi consent collection with your advertising strategies in Salesforce DMP. It means that Didomi automatically transmits consent data to Salesforce DMP Krux.</p><p>You can easily enable consent sharing from the Integrations section of your notice.</p>',
      prerequisites: ['Have a Salesforce DMP account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/salesforce-dmp-krux ',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/salesforce_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Advertising'],
  },

  /* Salesforce Marketing cloud */
  {
    id: 'sf_marketing_cloud',
    type_ids: ['sf-marketing-cloud'],
    template_id: 'didomi_subscription',
    name: 'Salesforce Marketing Cloud',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/sf_marketing_cloud.png',
      description:
        'Seamlessly synchronize first- and zero-party data to your Salesforce Marketing Cloud',
    },
    long_details: {
      website_link: 'https://www.salesforce.com/eu/products/marketing-cloud/overview/',
      headline:
        'Seamlessly synchronize first- and zero-party data to your Salesforce Marketing Cloud',
      long_description: `<p>Salesforce Marketing Cloud is a comprehensive digital marketing platform with extensive marketing automation capabilities.&nbsp;</p>
      <p>Didomi is offering native integration of PMP with Salesforce Marketing Cloud. The integration enables seamless and instantaneous synchronization of user preferences collected through Didomi&apos;s PMP to the appropriate contacts in the Audience Builder. In addition, the integration allows automatic contact creation in your Audience Builder, if no associated contact is found.&nbsp;</p>
      <p>Start collecting powerful zero- and first-party data, and create exceptional marketing campaigns in Salesforce Marketing Cloud.</p>`,
      prerequisites: [
        'Have a Salesforce Marketing Cloud account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/salesforce-marketing-cloud',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sf_marketing_cloud_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl:
      'https://calendly.com/didomi-customers/didomi-salesforce-marketing-cloud-introduction',
  },

  /* INT- 879*/
  {
    id: 'sf_sales_cloud',
    type_ids: ['sf-sales-cloud'],
    template_id: 'didomi_subscription',
    name: 'Salesforce Sales & Service Cloud',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/sf_sales_service_cloud.png',
      description:
        'Seamlessly sync your PMP data with Salesforce Sales Cloud and Service Cloud to personalise your marketing efforts.',
    },
    long_details: {
      website_link: 'https://www.salesforce.com',
      headline:
        'Seamlessly sync your PMP data with Salesforce Sales Cloud and Service Cloud to personalise your marketing efforts.',
      long_description: `Didomi's native Salesforce integration allows you to sync your lead and contact preferences to appropriate fields in your Salesforce Sales Cloud and Service Cloud platforms in real time.`,
      prerequisites: [
        'Salesforce Sales Cloud account or Service Cloud account',
        'Didomi Preference Center configured',
        'Users identified by unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/salesforce-sales-and-service-cloud',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/salesforce',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sf_sales_service_cloud_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['CRM', 'Marketing automation'],
  },

  {
    id: 'selligent',
    type_ids: ['selligent'],
    template_id: 'didomi_subscription',
    name: 'Selligent',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/selligent.png',
      description: 'Create targeted campaigns with Marigold Engage',
    },
    long_details: {
      website_link: 'https://www.selligent.com/',
      headline: 'Deliver adapted Marigold Engage marketing campaigns, based on user preferences',
      long_description: `
        <p>Marigold Engage is an AI-powered marketing automation platform. This integration allows you to update your Marigold Engage profile properties with the preferences collected by Didomi. Each consent event will be streamed in real time and shown in your Marigold Engage account. From here you can easily leverage your campaigns based on user choices.</p>
        <p>If you want to configure the Marigold Engage integration, you will need to provide:</p>
        <ul>
          <li>Marigold Engage user and password</li>
          <li>The URL of the Marigold Engage server</li>
          <li>The gate of your Marigold Engage campaign</li>
          <li>List of properties to match between Didomi preferences and Marigold Engage</li>
        </ul>
      `,
      prerequisites: [
        'Have an Marigold Engage account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/selligent',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/selligent_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-selligent-introduction',
  },

  /* V2 integration */
  {
    id: 'sendinblue',
    type_ids: ['sendinblue'],
    template_id: 'didomi_subscription',
    name: 'Sendinblue',
    is_draft: false,
    is_free: false,
    short_details: {
      logo_url: 'mvp-data-assets/logos/sendinblue.png',
      description: 'Optimize your marketing campaigns with preference data',
    },
    long_details: {
      website_link: 'https://www.brevo.com/',
      headline: 'Use Didomi Preference Management Platform to supercharge your Brevo campaigns',
      long_description:
        '<p>Brevo is an all-in-one marketing platform for small and medium-sized businesses, offering a range of cloud-based marketing solutions.</p><p>Integrate Brevo with Didomi to synchronize the preferences you collect with your Brevo contacts. This will allow you to seamlessly adjust your marketing campaigns based on user choices, and therefore increase the relevance of your Preference Management Platform (PMP).</p><p>Wishing to enrol for this integration ? All you need is your Brevo API key and the list of contact attributes.</p>',
      prerequisites: [
        'Have a Brevo account',
        'Have a Preference Center configured',
        'Identify your users by a unique identifier',
      ],
      documentation_links: [
        {
          title: 'Blog',
          url: 'https://blog.didomi.io/fr/didomi-x-sendinblue',
        },
        {
          title: 'Help Center',
          url: 'https://support.didomi.io/how-to-integrate-didomi-consents-to-my-sendinblue-account',
        },
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/integrations/third-party-apps/preference-management-platform-integrations/sendinblue',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/sendinblue_banner.png',
        },
      ],
    },
    compatibilities: ['PMP'],
    tags: ['Marketing automation'],
    meetingUrl: 'https://calendly.com/didomi-customers/didomi-sendinblue-introduction',
  },

  /* V2 integration */
  {
    id: 'tealium',
    type_ids: [],
    template_id: 'external_subscription',
    name: 'Tealium',
    is_draft: false,
    is_free: true,
    short_details: {
      logo_url: 'mvp-data-assets/logos/tealium.png',
      description: 'Boost Tealium customer data with consent information',
    },
    cta: {
      text: 'Go to Tealium',
      link: 'https://my.tealiumiq.com/',
    },
    long_details: {
      website_link: 'https://tealium.com/',
      headline: 'Include consent to your Tealium tags',
      long_description:
        '<p>Tealium IQ is a tag management solution that helps customers to properly manage tags on their websites.</p><p>The Tealium integration allows Didomi to send user consent information to Tealium. This transfer of information is in the form of calls and direct events that trigger tag loading, according to the consent. You can enable and disable Tealium tags directly in the "Integrations" section of your notice.</p>',
      prerequisites: ['Have a Tealium account', 'Have a CMP configured'],
      documentation_links: [
        {
          title: 'Developer documentation',
          url: 'https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/tealium ',
        },
      ],
      carousel: [
        {
          type: 'image',
          url: 'mvp-data-assets/carousel/images/tealium_banner.png',
        },
      ],
    },
    compatibilities: ['CMP'],
    tags: ['Tag manager'],
  },
];
