import React from 'react';
import { DidomiCardContainer, DidomiIconButton } from '@didomi/ui-atoms-react';

interface SubscriptionsListCardProps {
  logoSrc: string;
  hasEditAccess: boolean;
}

export const SubscriptionsListCard = ({ logoSrc, hasEditAccess }: SubscriptionsListCardProps) => {
  return (
    <DidomiCardContainer style={{ '--card-inner-padding': 0 }}>
      {/* Header */}
      <div className="bg-neutral-gray-1 pt-[74px] px-s pb-m rounded-lg">
        <img className="mx-auto h-[56px]" src={logoSrc} alt="" />
      </div>

      <div className="p-xxs flex justify-end h-[52px]">
        {hasEditAccess && (
          <DidomiIconButton
            icon="settings"
            variant="rounded"
            aria-label="Open Configuration"
          ></DidomiIconButton>
        )}
      </div>
    </DidomiCardContainer>
  );
};
