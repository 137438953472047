export const RELATIVE_ROUTES = {
  CONFIGURE_SUBSCRIPTION_SETUP: 'setup',
  CONFIGURE_SUBSCRIPTION_SETTINGS: 'settings',
  CONFIGURE_SUBSCRIPTION_OPTIONS: 'options',
  CONFIGURE_SUBSCRIPTION_MAPPINGS: 'mappings',
  CONFIGURE_SUBSCRIPTION_SUCCESS: 'success',
};

export const ROUTES = {
  EXPLORE: '/explore',
  INTEGRATION_DETAILS: '/explore/:integrationId',
  MANAGE: '/manage',
  CONFIGURE_SUBSCRIPTION: '/manage/:integrationId',
  CONFIGURE_SUBSCRIPTION_SETUP: `/manage/:integrationId/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP}`,
  CONFIGURE_SUBSCRIPTION_SETTINGS: `/manage/:integrationId/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP}/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETTINGS}`,
  CONFIGURE_SUBSCRIPTION_OPTIONS: `/manage/:integrationId/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP}/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_OPTIONS}`,
  CONFIGURE_SUBSCRIPTION_MAPPINGS: `/manage/:integrationId/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP}/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_MAPPINGS}`,
  CONFIGURE_SUBSCRIPTION_SUCCESS: `/manage/:integrationId/${RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SUCCESS}`,
};
