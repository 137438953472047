import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { INTEGRATIONS_DATA, QUERY_KEYS } from '@constants';
import { Integration } from '@types';

/**
 * Fetch all integrations - return integrations listed in the INTEGRATIONS_DATA file
 * */
export const useIntegrations = (options?: UseQueryOptions<Integration[]>) => {
  const fetchIntegrations = (): Integration[] => INTEGRATIONS_DATA;

  return useQuery<Integration[], AxiosError>(QUERY_KEYS.INTEGRATIONS, fetchIntegrations, options);
};
