import * as Yup from 'yup';
import { FormField } from '@types';
import { isValidJson } from './isValidJson';

const ERROR_TEXT = {
  REQUIRED: 'Please fill this field.',
  URL: 'Please enter a valid URL.',
  MIN_ITEMS: (n: number) => `Please select at least ${n} item.`,
  MAX_ITEMS: (n: number) => `Please select at most ${n} item.`,
  JSON: 'Please enter valid JSON object.',
};

const getStringValidator = (field: FormField) => {
  let baseSchema = Yup.string();

  if (field.required) baseSchema = baseSchema.required(ERROR_TEXT.REQUIRED);
  if (field.format === 'url') baseSchema = baseSchema.url(ERROR_TEXT.URL);

  // dependsOn is supported only for string fields for the now
  if (field.dependsOn) {
    baseSchema = baseSchema.when(field.dependsOn, {
      is: false,
      then: schema => schema.optional(),
    });
  }

  return baseSchema;
};

const getFieldValidator = (field: FormField) => {
  switch (field.type) {
    case 'number':
      return Yup.number().when([], (_, schema) =>
        field.required ? schema.required(ERROR_TEXT.REQUIRED) : schema,
      );
    case 'boolean':
      return Yup.boolean().when([], (_, schema) =>
        field.required ? schema.required(ERROR_TEXT.REQUIRED) : schema,
      );
    case 'array':
      return Yup.array()
        .min(field.minItems || 0, ERROR_TEXT.MIN_ITEMS(field.minItems))
        .max(field.maxItems || Infinity, ERROR_TEXT.MAX_ITEMS(field.maxItems));
    case 'object':
      return Yup.string().test('json', ERROR_TEXT.JSON, isValidJson);
    default:
      return getStringValidator(field);
  }
};

export const getDynamicValidator = (fields: FormField[]) => {
  const schema = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.formControlName]: getFieldValidator(field),
    };
  }, {});

  return Yup.object().shape(schema);
};
