import { AccessTypes, AvailableModules, modules } from '@didomi/authorization-metadata';

const MARKETPLACE_MANAGE_POLICIES_METADATA = modules[AvailableModules.MarketplaceManage];

// Marketplace manage access policies config
export const ACCESS_POLICIES_CONFIG = {
  MARKETPLACE_MANAGE_VIEWER:
    MARKETPLACE_MANAGE_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  MARKETPLACE_MANAGE_EDITOR:
    MARKETPLACE_MANAGE_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
};
