import React from 'react';
import { DidomiChip, DidomiLargePush, DidomiLink } from '@didomi/ui-atoms-react';
import { Integration } from '@types';
import { getShortUrl } from '@utils';
import { InlineList } from '../InlineList/InlineList';
import { IntegrationPrerequisites } from '../IntegrationPrerequisites/IntegrationPrerequisites';

interface IntegrationFullInfoProps {
  className?: string;
  integration: Integration;
}
export const IntegrationFullInfo = ({ className, integration }: IntegrationFullInfoProps) => {
  return (
    <section className={className}>
      {/* Name(a11y) */}
      <h1 className="sr-only" data-cy="integration-title">
        {integration.name}
      </h1>

      {/* Compatibilities */}
      {integration.compatibilities.length > 0 && (
        <div className="flex items-center text-neutral-gray-0 text-body-small font-semibold mb-m">
          {integration.is_free && (
            <DidomiChip data-cy="integration-label" label="Free" className="mr-xs" />
          )}
          Available for:&nbsp;
          <InlineList
            items={integration.compatibilities}
            separator="·"
            data-cy="integration-compatibilities"
          />
        </div>
      )}

      {/* Headline */}
      <h2 className="text-h2 leading-h2 font-bold mb-m" data-cy="integration-headline">
        {integration.long_details.headline}
      </h2>

      {/* Description */}
      <div
        className="raw-html-content mb-m"
        dangerouslySetInnerHTML={{ __html: integration.long_details.long_description }}
        data-cy="integration-description"
      ></div>

      {/* Link */}
      {integration.long_details.website_link && (
        <DidomiLink
          className="mb-m block"
          href={integration.long_details.website_link}
          text={getShortUrl(integration.long_details.website_link)}
          target="_blank"
          iconName={'website' as any}
          iconPosition="left"
          size="large"
          is-underlined="false"
          data-cy="integration-website-link"
          data-tracking="marketplace-integration-partner-link"
        />
      )}

      <div className="border-solid border-b-1 border-neutral-gray-4 mb-m"></div>

      {/* Pre-requisites */}
      {integration.long_details.prerequisites.length > 0 && (
        <IntegrationPrerequisites
          className="mb-m"
          prerequisites={integration.long_details.prerequisites}
        />
      )}

      {/* Documentation Links */}
      {integration.long_details.documentation_links.length > 0 && (
        <div className="flex flex-col gap-xs" data-cy="integration-docs-links">
          {integration.long_details.documentation_links.map(link => (
            <DidomiLargePush
              key={link.url}
              href={link.url}
              text={link.title}
              highlightedText={link.sub_title}
              target="_blank"
              data-tracking="marketplace-integration-documentation-link"
            />
          ))}
        </div>
      )}
    </section>
  );
};
