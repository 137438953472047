import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';

interface IntegrationPricingCardProps {
  startingPrice: number;
  onBookDemoClicked: () => void;
}

export const IntegrationPricingCard = ({
  startingPrice,
  onBookDemoClicked,
}: IntegrationPricingCardProps) => {
  return (
    <section
      className="bg-neutral-gray-1 rounded-lg p-m text-center shadow-small-push"
      data-cy="integration-pricing-card"
    >
      <div className="text-h3 font-bold mb-s">Flexible prices that adapt to your growth!</div>

      <h3 className="text-h1 font-bold leading-none mb-xs">from {startingPrice} € / month</h3>

      <div className="text-secondary-cobalt-blue-3 font-semibold mb-m">Extra fees may apply.</div>

      <DidomiButton data-tracking="marketplace-subscribe-button" onClick={onBookDemoClicked}>
        Subscribe
      </DidomiButton>
    </section>
  );
};
