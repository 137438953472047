import React from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';

interface IntegrationPrerequisitesProps {
  className?: string;
  prerequisites: string[];
}

export const IntegrationPrerequisites = ({
  className,
  prerequisites,
}: IntegrationPrerequisitesProps) => (
  <div className={className} data-cy="pre-requisites-list">
    <h3 className="text-h3 leading-6 font-serif mb-xs">Prerequisites:</h3>
    <ul>
      {prerequisites.map(preRequisite => (
        <li key={preRequisite} className="flex items-center gap-xxs font-semibold">
          <DidomiIcon className="flex-shrink-0 w-[15px]" name="success" />
          {preRequisite}
        </li>
      ))}
    </ul>
  </div>
);
