import React, { useEffect, useState } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalContent,
  DidomiModalHeader,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { SPA_FULL_NAME } from '@constants';

interface RequestIntegrationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (requestText: string) => void;
}

export const RequestIntegrationModal = ({
  isOpen,
  onCancel,
  onSubmit,
}: RequestIntegrationModalProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const [requestText, setRequestText] = useState('');

  useEffect(() => {
    // Reset the request text when the modal is closed
    if (!isOpen) {
      setRequestText('');
    }
  }, [isOpen]);

  const handleOpenChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal
      isOpen={isOpen}
      initialFocus="#request-integration-text-input input"
      returnFocusAfterClose={false}
      onOpenChange={handleOpenChange}
    >
      <img
        src={`${ASSETS_URL}/assets/integrations-collection.png`}
        alt=""
        className={tx('block mb-s h-[100px]')}
      />

      <DidomiModalHeader
        className={tx('px-xs mb-xxs')}
        modalSubTitle="Let us know what you need..."
        modalTitle="We're constantly building new integrations, tell us which integrations might interest you?"
      />

      <DidomiModalContent className={tx('w-full px-s')}>
        <DidomiTextInput
          id="request-integration-text-input"
          value={requestText}
          placeholder="Hello, I need a..."
          hideErrorMessage
          data-cy="request-integration-input"
          onValueChange={e => setRequestText(e.detail)}
          onKeyUp={e => {
            if (e.key === 'Enter' && requestText) {
              onSubmit(requestText);
            }
          }}
        />
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton onClick={onCancel}>Cancel</DidomiButton>
        <DidomiButton
          disabled={!requestText}
          data-tracking="marketplace-request-integration-button"
          data-cy="modal-primary-action"
          onClick={() => onSubmit(requestText)}
        >
          Send my request
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
