import { useMemo } from 'react';
import { matchId } from '@didomi/helpers';
import { useSnackbar } from '@didomi/utility-react';
import { useCreateSubscriptionConfig } from './useCreateSubscriptionConfig.hook';
import { useIntegrations } from './useIntegrations.hook';
import { usePremiumFeatures } from './usePremiumFeatures.hook';
import { useSubscriptionConfigs } from './useSubscriptionConfigs.hook';
import { useSubscriptionConfigTypes } from './useSubscriptionTypes.hook';
import { useUpdateSubscriptionConfig } from './useUpdateSubscriptionConfig.hook';

const SNACKBAR_DURATION = 5000;

export const useSubscriptionsApi = (integrationId?: string) => {
  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrations();
  const { data: premiumFeatures, isLoading: isLoadingPremiumFeatures } = usePremiumFeatures();
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while saving the changes`;
    displaySnackbar(message, { variant: 'error', duration: SNACKBAR_DURATION });
  };

  const activeSubscriptions = useMemo(() => {
    if (!integrations || !premiumFeatures) {
      return [];
    }

    return integrations.filter(integration => premiumFeatures[integration.id]);
  }, [integrations, premiumFeatures]);

  const subscription = useMemo(() => {
    if (integrationId && activeSubscriptions.length) {
      return activeSubscriptions.find(matchId(integrationId));
    }
  }, [activeSubscriptions, integrationId]);

  const { data: subscriptionConfigTypes, isLoading: isSubscriptionConfigTypesLoading } =
    useSubscriptionConfigTypes(subscription?.type_ids);

  const { data: subscriptionConfigs, isLoading: isSubscriptionConfigsLoading } =
    useSubscriptionConfigs(subscription?.type_ids);

  // Mutations
  const { mutate: createConfig, isLoading: isCreating } = useCreateSubscriptionConfig({
    onSuccess: () => displaySnackbar(`Settings have been saved.`, { icon: 'check' }),
    onError: handleApiError,
  });

  const { mutate: updateConfig, isLoading: isUpdating } = useUpdateSubscriptionConfig({
    onSuccess: () => displaySnackbar(`Settings have been saved.`, { icon: 'check' }),
    onError: handleApiError,
  });

  const isLoading =
    isIntegrationsLoading ||
    isSubscriptionConfigTypesLoading ||
    isSubscriptionConfigsLoading ||
    isLoadingPremiumFeatures;

  return {
    subscriptions: activeSubscriptions,
    subscription,
    subscriptionConfigTypes,
    subscriptionConfigs,
    isLoading,
    isCreating,
    isUpdating,
    createConfig,
    updateConfig,
  };
};
