import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  DidomiButton,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
} from '@didomi/ui-atoms-react';
import { ConfigurationForm } from '@components';
import { useSubscriptionSetup, useSubscriptionSetupNavigation, useSubscriptionsApi } from '@hooks';
import { BlockNavigationModal, LoadingModal, SettingsFormEditWarningModal } from '@modals';
import { ROUTES, transformConfigFormToPayload } from '@utils';

export const ConfigureSubscriptionSettings = () => {
  const navigate = useNavigate();
  const { proceedToStep } = useSubscriptionSetupNavigation();
  const { integrationId } = useParams();
  const {
    subscriptionConfigTypes,
    subscriptionConfigs,
    isCreating,
    createConfig,
    isUpdating,
    updateConfig,
  } = useSubscriptionsApi(integrationId);
  const [currentConfigTypeId, setCurrentConfigTypeId] = useState(null);
  const { subscriptionConfig, subscriptionConfigType, settingsFormFields } = useSubscriptionSetup(
    integrationId,
    currentConfigTypeId,
  );
  const [formStatus, setFormStatus] = useState<
    'create' | 'edit-disabled' | 'edit-warning' | 'edit'
  >('create');

  useEffect(() => {
    // Set form status to 'edit-disabled' if subscription config exists
    // Before editing ensure client is aware of the impact of changing the settings info. Any update to the settings may break the integration as it contains the connectivity part.
    if (subscriptionConfig) {
      setFormStatus('edit-disabled');
    }
  }, [subscriptionConfig]);

  useEffect(() => {
    // Set current config type id - default to first one
    const configTypeId = subscriptionConfig?.type_id || subscriptionConfigTypes?.[0]?.id;
    setCurrentConfigTypeId(configTypeId);
  }, [subscriptionConfig, subscriptionConfigTypes]);

  const getApiPayload = formValues => {
    const authenticationRequiredValue = formValues.authentication_required;
    // delete authentication_required from value - to avoid sending it in API call
    delete formValues.authentication_required;

    const payload = transformConfigFormToPayload(
      formValues,
      {
        type_id: currentConfigTypeId,
        mappings: subscriptionConfigs[0]?.mappings || [],
        properties: subscriptionConfigs[0]?.properties || {},
        enabled: true,
      },
      settingsFormFields,
    );

    // send empty object for `authentication`, if authentication not required
    if (!subscriptionConfigType.authentication_required && authenticationRequiredValue === false) {
      payload.authentication = {};
    }

    return payload;
  };

  const handleSaveSettings = (values, proceedNavCb?: () => void) => {
    const payload = getApiPayload(values);
    // After save success, navigate to the next step or proceed with the router blocking navigation
    const onSuccess = () => (proceedNavCb ? proceedNavCb() : proceedToStep(2));

    if (subscriptionConfig) {
      updateConfig({ configId: subscriptionConfig.id, config: payload }, { onSuccess });
    } else {
      createConfig(payload, { onSuccess });
    }
  };

  const isCreationFlow = subscriptionConfigs?.length === 0;

  return (
    <div className="pb-[100px]">
      {settingsFormFields?.length > 0 && (
        <ConfigurationForm
          fields={settingsFormFields}
          disabled={formStatus === 'edit-disabled'}
          typeSchemaSelect={
            // Type Schema Select
            subscriptionConfigTypes?.length > 1 && (
              <DidomiSelect
                label="Choose an integration type"
                value={currentConfigTypeId}
                disabled={!isCreationFlow}
                onValueChange={e => setCurrentConfigTypeId(e.detail)}
              >
                <DidomiSelectOptions>
                  {subscriptionConfigTypes?.map(type => (
                    <DidomiSelectOption key={type.id} label={type.name} value={type.id} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelect>
            )
          }
          onSubmit={values => handleSaveSettings(values)}
        >
          {({ isValid, dirty, values }) => (
            <>
              {/* Form Actions */}
              <div className="fixed bottom-0 flex gap-xs justify-center w-full -mx-l p-xs bg-white border-solid border-t-1 border-neutral-gray-3">
                <DidomiButton
                  variant="secondary"
                  type="reset"
                  onClick={() => navigate(ROUTES.MANAGE)}
                >
                  Cancel
                </DidomiButton>
                {formStatus === 'edit-disabled' ? (
                  <DidomiButton key="edit-settings" onClick={() => setFormStatus('edit-warning')}>
                    Edit
                  </DidomiButton>
                ) : (
                  <DidomiButton key="save-settings" type="submit" disabled={!isValid || !dirty}>
                    Save settings
                  </DidomiButton>
                )}
              </div>

              <BlockNavigationModal
                isBlocked={dirty && !isCreating && !isUpdating}
                isSaveAllowed={isValid}
                onSaveAndProceedClicked={proceedNavCb => handleSaveSettings(values, proceedNavCb)}
              />
            </>
          )}
        </ConfigurationForm>
      )}

      {/* Modals */}
      <LoadingModal isOpen={isCreating || isUpdating} title="Saving changes..." />
      <SettingsFormEditWarningModal
        isOpen={formStatus == 'edit-warning'}
        onClose={() => setFormStatus('edit')}
      />
    </div>
  );
};
