import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { SubscriptionConfig } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

// Fetch all subscriptions configs for a provided list of typeIds
// UI would use by default the first config that is present
export const useSubscriptionConfigs = (
  typeIds: string[],
  options?: UseQueryOptions<SubscriptionConfig[]>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchSubscriptionConfig = async (typeId: string) => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<SubscriptionConfig>>(
      `${CONFIG.environmentConfig.apiBaseUrl}marketplace/integrations?type_id=${typeId}&organization_id=${organizationId}`,
    );
    return response.data.data;
  };

  const fetchSubscriptionConfigs = async () => {
    const responses = await Promise.all(typeIds.map(fetchSubscriptionConfig));
    // Get configured configs
    return responses.flat().filter(Boolean);
  };

  return useQuery(QUERY_KEYS.SUBSCRIPTION_CONFIGS(typeIds), fetchSubscriptionConfigs, {
    enabled: !!typeIds,
    ...options,
  });
};
