import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiTreeCardBranch, DidomiTreeCardBranchItem } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ConfigTreePreferenceWrapper, MappingOption } from '@types';
import { MappingField } from 'components/MappingField/MappingField';

interface MappingPreferencesTreeProps {
  preferences: ConfigTreePreferenceWrapper[];
  mappingOptions: MappingOption[];
}

export const MappingPreferencesTree = ({
  preferences,
  mappingOptions,
}: MappingPreferencesTreeProps) => {
  return (
    <div>
      {preferences.map(preferenceWrapper => (
        <DidomiTreeCardBranch
          key={preferenceWrapper.id}
          headerText={getTranslatedValue(preferenceWrapper.preference.name)}
        >
          {/* Preference Controls */}
          <div slot="header-controls">
            <MappingField name={preferenceWrapper.id} mappingOptions={mappingOptions} />
          </div>

          {preferenceWrapper.preference.values.map(value => (
            <>
              <DidomiTreeCardBranchItem key={value.id} text={getTranslatedValue(value.name)}>
                {/* Value Controls */}
                <div
                  slot="controls"
                  className={tx({ 'mb-xs': value.selected_preferences?.length })}
                >
                  <MappingField
                    name={preferenceWrapper.id + '_' + value.id}
                    mappingOptions={mappingOptions}
                  />
                </div>
              </DidomiTreeCardBranchItem>

              {/* Recursively iterate over preferences */}
              <MappingPreferencesTree
                preferences={value.selected_preferences}
                mappingOptions={mappingOptions}
              />
            </>
          ))}
        </DidomiTreeCardBranch>
      ))}
    </div>
  );
};
