import React, { useState } from 'react';
import { DidomiCtaPopIn } from '@didomi/ui-atoms-react';
import { RequestIntegrationModal } from '@modals';

export const RequestIntegrationWidget = ({ onSubmit }: { onSubmit: (text: string) => void }) => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);

  return (
    <>
      <DidomiCtaPopIn
        class="fixed bottom-xs"
        style={{ '--pop-in-icon-color': '#ffd5c8' }}
        lead-icon="target"
        description="Can't find what you need?"
        button-text="Do a request"
        data-cy="request-integration-trigger"
        data-tracking="marketplace-show-request-dialog"
        onBtnClicked={() => setIsRequestModalOpen(true)}
      />

      {/* Modals */}
      <RequestIntegrationModal
        isOpen={isRequestModalOpen}
        onCancel={() => setIsRequestModalOpen(false)}
        onSubmit={requestText => {
          setIsRequestModalOpen(false);
          onSubmit(requestText);
        }}
      />
    </>
  );
};
