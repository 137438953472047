import React from 'react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';

interface SettingsFormEditWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsFormEditWarningModal = ({
  isOpen,
  onClose,
}: SettingsFormEditWarningModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim" returnFocusAfterClose={false}>
      <DidomiModalHeader
        illustrationName="warning-modal"
        modalTitle="All modifications of settings will impact other sections."
        modalSubTitle="Pay attention"
      />

      <DidomiModalActions>
        <DidomiButton onClick={onClose}>Ok, I understand</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
