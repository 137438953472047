import { NavLink, useParams } from 'react-router-dom';
import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { useSubscriptionsApi } from '@hooks';
import { ROUTES } from '@utils';

export const ConfigureSubscriptionSuccess = () => {
  const { integrationId } = useParams();
  const { subscription } = useSubscriptionsApi(integrationId);

  return (
    <section className="h-full grid place-content-center text-center">
      <h1 className="h1-catchline-medium text-primary-blue-6 mb-xxs">Congratulations!</h1>

      <div className="text-body-small mb-m">{subscription?.name} is now installed</div>

      <div className="flex gap-xs">
        <NavLink to={ROUTES.EXPLORE}>
          <DidomiButton variant="secondary">Back to Marketplace</DidomiButton>
        </NavLink>
        <NavLink to={ROUTES.MANAGE}>
          <DidomiButton>Go to Manage</DidomiButton>
        </NavLink>
      </div>

      {/* full page background */}
      <div className="absolute inset-0 -z-10 bg-rainbow"></div>
    </section>
  );
};
