import { Integration } from '@types';

export const PMP_DEMO_URL = 'https://calendly.com/didomi-customers/didomi-pc-introduction-en';

export const getIntegrationMeetingUrl = (
  integration: Integration,
  isPmpEnabled: boolean,
): string => {
  const isCmpCompatible = integration.compatibilities.includes('CMP');

  return isCmpCompatible || isPmpEnabled ? integration.meetingUrl : PMP_DEMO_URL;
};
