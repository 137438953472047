import { useActiveOrganization } from '@didomi/utility-react';
import { UseMutationOptions, useMutation } from 'react-query';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useRequestIntegration = (options?: UseMutationOptions<unknown, Error, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const requestIntegration = async (requestText: string) => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}integrations-feedbacks?organization_id=${organizationId}`;
    const response = await axiosWithInterceptors.post<unknown>(url, {
      organization_id: organizationId,
      content: requestText,
    });

    return response;
  };

  return useMutation(requestIntegration, options);
};
