import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import React from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { ErrorState, ProtectedRoute } from '@components';
import { ACCESS_POLICIES_CONFIG } from '@constants';
import { ConfigureSubscriptionLayout, HomeLayout } from '@layouts';
import {
  ConfigureSubscriptionMappings,
  ConfigureSubscriptionOptions,
  ConfigureSubscriptionSettings,
  ConfigureSubscriptionSteps,
  ConfigureSubscriptionSuccess,
  ExploreIntegrations,
  IntegrationDetails,
  ManageIntegrations,
} from '@pages';
import { ROUTES, RELATIVE_ROUTES } from '@utils';

const getRouter = (basename: string) =>
  createBrowserRouter(
    [
      {
        path: '/',
        errorElement: <ErrorState />,
        children: [
          {
            path: '',
            element: <HomeLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.EXPLORE} />,
              },
              {
                path: ROUTES.EXPLORE,
                element: <ExploreIntegrations />,
              },
              {
                path: ROUTES.MANAGE,
                element: (
                  <ProtectedRoute
                    redirectPath={ROUTES.EXPLORE}
                    requiredAccessPolicies={ACCESS_POLICIES_CONFIG.MARKETPLACE_MANAGE_VIEWER}
                  >
                    <ManageIntegrations />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.INTEGRATION_DETAILS,
            element: <IntegrationDetails />,
          },
          {
            path: ROUTES.CONFIGURE_SUBSCRIPTION,
            element: (
              <ProtectedRoute
                redirectPath={ROUTES.MANAGE}
                requiredAccessPolicies={ACCESS_POLICIES_CONFIG.MARKETPLACE_MANAGE_EDITOR}
              >
                <ConfigureSubscriptionLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP,
                element: <ConfigureSubscriptionSteps />,
                children: [
                  {
                    path: RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETTINGS,
                    element: <ConfigureSubscriptionSettings />,
                  },
                  {
                    path: RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_OPTIONS,
                    element: <ConfigureSubscriptionOptions />,
                  },
                  {
                    path: RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_MAPPINGS,
                    element: <ConfigureSubscriptionMappings />,
                  },
                  {
                    path: '',
                    element: <Navigate to={RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETTINGS} />,
                  },
                ],
              },
              {
                path: RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SUCCESS,
                element: <ConfigureSubscriptionSuccess />,
              },
              {
                path: '',
                element: <Navigate to={RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETUP} />,
              },
            ],
          },
        ],
      },
    ],
    { basename },
  );

export const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();
  const basename = `/${organizationId}/marketplace`;

  return <RouterProvider router={getRouter(basename)} />;
};
