import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { SubscriptionConfig } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useUpdateSubscriptionConfig = (
  options?: UseMutationOptions<
    AxiosResponse<SubscriptionConfig>,
    AxiosError<{ message: string }>,
    { configId: string; config: Partial<SubscriptionConfig> }
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const updateConfigInCache = (config: SubscriptionConfig) => {
    queryClient.setQueriesData<SubscriptionConfig[]>({ queryKey: ['subscription-configs'] }, () => [
      config,
    ]);
  };

  return useMutation(
    async ({ configId, config }) => {
      return axiosWithInterceptors.patch(
        `${CONFIG.environmentConfig.apiBaseUrl}marketplace/integrations/${configId}?organization_id=${organizationId}`,
        config,
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<SubscriptionConfig>, config, context) => {
        updateConfigInCache(response.data);
        options?.onSuccess?.(response, config, context);
      },
    },
  );
};
