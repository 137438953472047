import { SubscriptionConfig, FieldSchema } from '@types';
import { toTitleCase } from './toTitleCase';

interface GetFormFieldConfigParams {
  fieldSchema: FieldSchema;
  subscriptionConfig: SubscriptionConfig;
  parentKey: string;
  fieldKey: string;
  requiredByDefault: boolean;
}

const FIELD_TYPE = {
  ARRAY: 'array',
  STRING: 'string',
  OBJECT: 'object',
};

export const getSelectOptions = (fieldSchema: FieldSchema) => {
  return (
    fieldSchema.items?.enum.map(option => ({
      label: toTitleCase(option.replaceAll('-', ' ')),
      value: option,
    })) || []
  );
};

export const getDefaultValue = (type: string) => {
  return type === FIELD_TYPE.ARRAY ? [] : type === FIELD_TYPE.STRING ? '' : null;
};

export const getSafeFieldValue = (fieldSchema: FieldSchema, value) => {
  if (fieldSchema.type === FIELD_TYPE.OBJECT) {
    return JSON.stringify(value || {}, null, 2);
  } else {
    return value || getDefaultValue(fieldSchema.type);
  }
};

export const getFormFieldConfig = ({
  fieldSchema,
  subscriptionConfig,
  parentKey,
  fieldKey,
  requiredByDefault,
}: GetFormFieldConfigParams) => {
  // Check whether field is the secret and has value
  const isSecretWithValue = fieldSchema.format === 'secret' && subscriptionConfig;
  // Use asterisks(*) for placeholder if secret was provided before
  const placeholder = isSecretWithValue ? '******' : '';

  return {
    value: getSafeFieldValue(fieldSchema, subscriptionConfig?.[parentKey][fieldKey]),
    // e.g. `authentication.client_id.secret`
    formControlName: `${parentKey}/${fieldKey}${
      fieldSchema.format ? '/' + fieldSchema.format : ''
    }`,
    placeholder,
    // Secret field is optional during the edit
    required: isSecretWithValue ? false : requiredByDefault,
    // extract the available select options if the field type is array
    options: getSelectOptions(fieldSchema),
  };
};
