import React from 'react';
import { DidomiEmptyState } from '@didomi/ui-atoms-react';
import { Loader, SubscriptionsList } from '@components';
import { useSubscriptionsApi } from '@hooks';

export const ManageIntegrations = () => {
  const { subscriptions, isLoading } = useSubscriptionsApi();

  const isEmpty = subscriptions?.length === 0;

  return (
    <section className="w-full">
      {!isEmpty && <SubscriptionsList subscriptions={subscriptions} />}

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState illustration-name="traces-no-result-found">
          <div slot="title">You don&apos;t have any subscriptions yet!</div>
          Please contact your customer support to avail a new subscription
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <Loader>Loading subscriptions</Loader>}
    </section>
  );
};
