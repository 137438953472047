import { produce } from '@didomi/helpers';
import { FormField, SubscriptionConfig } from '@types';

/*
 * Transform form value to integration config payload
 * { 'authentication/target': 'Target1' } => { authentication: { target: 'Target1' } }
 * { 'upsert_mode': true } => { upsert_mode: true }
 */
export const transformConfigFormToPayload = (
  formValue: Record<string, string | boolean | Array<string>>,
  baseObj = {},
  fields: FormField[] = [],
): Partial<SubscriptionConfig> => {
  return Object.entries(formValue).reduce((acc, [fieldName, value]) => {
    // 'authentication/target/secret' => ['authentication', 'target', 'secret']
    const [keyGroup, key, format] = fieldName.split('/');
    const isEmptySecret = format === 'secret' && !value;

    // empty secrets should not be sent - so we skip them
    if (isEmptySecret) {
      return acc;
    }

    const formField = fields.find(({ formControlName }) => formControlName === fieldName);
    const isJsonField = formField?.type === 'object';

    // // UI Form works with JSON string, we need to parse it before sending to the API
    // // String should be a valid JSON string as it is validated by the form
    const valueToSet = isJsonField ? JSON.parse(value as string) : value;

    // We might have values at the root level(no key)
    const pathToSet = [keyGroup, key].filter(Boolean).join('.');

    return produce(acc, pathToSet, valueToSet);
  }, baseObj);
};
