import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { MappingOption } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch mapping options for the subscription
 * */
export const useSubscriptionMappingOptions = (
  configId: string,
  options?: UseQueryOptions<MappingOption[]>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchMappingOptions = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<MappingOption>>(
      `${CONFIG.environmentConfig.apiBaseUrl}marketplace/integrations/${configId}/mappings?organization_id=${organizationId}`,
    );
    return response.data.data;
  };

  return useQuery<MappingOption[], AxiosError>(
    QUERY_KEYS.MAPPING_OPTIONS(configId),
    fetchMappingOptions,
    {
      ...options,
      enabled: !!configId,
    },
  );
};
