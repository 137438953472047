import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { ConfigTree } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch configuration tree for current organization
 * First returned configuration is used
 * */
export const useConfigTree = (options?: UseQueryOptions<ConfigTree>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchConfigTree = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConfigTree>>(
      `${CONFIG.environmentConfig.apiBaseUrl}config-trees?%24with%5B%5D=purpose&%24with%5B%5D=preference&organization_id=${organizationId}`,
    );
    return response.data.data[0];
  };

  return useQuery<ConfigTree, AxiosError>(
    QUERY_KEYS.CONFIG_TREE(organizationId),
    fetchConfigTree,
    options,
  );
};
