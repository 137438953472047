import React, { useMemo } from 'react';
import { DidomiModal } from '@didomi/ui-atoms-react';
import { useUserProfile } from '@didomi/utility-react';
import { InlineWidget } from 'react-calendly';
import { Integration } from '@types';
import { getIntegrationMeetingUrl } from '@utils';

const CALENDLY_WIDGET_SETTINGS = {
  backgroundColor: 'ffffff',
  hideEventTypeDetails: true,
  hideLandingPageDetails: true,
  primaryColor: '265973',
  textColor: '4d5055',
  hideGdprBanner: true,
};

type BookingModalProps = {
  isOpen: boolean;
  integration: Integration;
  isPmpEnabled: boolean;
  onCancel: () => void;
};

export const BookingModal = ({
  isOpen,
  integration,
  isPmpEnabled,
  onCancel,
}: BookingModalProps) => {
  const [userProfile] = useUserProfile();

  const url = useMemo(() => {
    return getIntegrationMeetingUrl(integration, isPmpEnabled);
  }, [integration, isPmpEnabled]);

  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} closable openChange={handleOnChange}>
      {url && (
        <InlineWidget
          url={url}
          pageSettings={CALENDLY_WIDGET_SETTINGS}
          prefill={{ name: userProfile?.name, email: userProfile?.email }}
        />
      )}
    </DidomiModal>
  );
};
