import { Integration, IntegrationsFilters, PurchaseType, SubscriptionType } from '@types';

const matchPurchaseType = (
  purchaseType: IntegrationsFilters['purchaseType'],
  integration: Integration,
) => {
  if (!purchaseType) return true;

  return (
    (purchaseType === PurchaseType.FREE && integration.is_free) ||
    (purchaseType === PurchaseType.PAID && !integration.is_free)
  );
};

const matchSubscriptionType = (
  subscriptionType: IntegrationsFilters['subscriptionType'],
  integration: Integration,
) => {
  if (!subscriptionType) return true;

  return (
    (subscriptionType === SubscriptionType.PREMIUM_FEATURE &&
      integration.template_id === SubscriptionType.PREMIUM_FEATURE) ||
    (subscriptionType === SubscriptionType.CONNECTOR &&
      integration.template_id === SubscriptionType.CONNECTOR)
  );
};

const matchCompatibilities = (
  compatibilities: IntegrationsFilters['compatibilities'],
  integration: Integration,
) => {
  if (!compatibilities?.length) return true;

  return compatibilities.some(compatibility => integration.compatibilities.includes(compatibility));
};

const matchCategories = (tags: IntegrationsFilters['categories'], integration: Integration) => {
  if (!tags?.length) return true;

  return tags.some(tag => integration.tags.includes(tag));
};

export const matchIntegrationsFilters =
  (filters: IntegrationsFilters) => (integration: Integration) => {
    if (!filters) return true;

    const { purchaseType, subscriptionType, compatibilities, categories } = filters;

    return (
      matchPurchaseType(purchaseType, integration) &&
      matchSubscriptionType(subscriptionType, integration) &&
      matchCompatibilities(compatibilities, integration) &&
      matchCategories(categories, integration)
    );
  };
