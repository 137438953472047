import React, { useMemo, useState } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiCarousel, DidomiCarouselSlide } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { BookingModal, PreBookingModal } from '@modals';
import { Integration } from '@types';
import { IntegrationPricingCard } from 'components/IntegrationPricingCard/IntegrationPricingCard';
import { IntegrationPromoPush } from 'components/IntegrationPromoPush/IntegrationPromoPush';
import { SPA_FULL_NAME } from '@constants';

interface IntegrationInfoBannerProps {
  className?: string;
  integration: Integration;
  isIntegrationEnabled: boolean;
  isPmpEnabled: boolean;
}

export const IntegrationInfoBanner = ({
  className,
  integration,
  isIntegrationEnabled,
  isPmpEnabled,
}: IntegrationInfoBannerProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const [dialogState, setDialogState] = useState<'pre-booking' | 'booking'>(null);

  const {
    organization: { self_register: isSelfService },
  } = useActiveOrganization();

  const isCmpCompatible = useMemo(() => integration.compatibilities.includes('CMP'), [integration]);

  const promoPushTitle =
    isCmpCompatible || isPmpEnabled ? 'Great choice!' : 'Create targeted experiences!';

  const isPromoPushVisible =
    !isIntegrationEnabled && integration.meetingUrl && !integration.pricing && !isSelfService;
  const isPricingVisible = !isIntegrationEnabled && integration.pricing && !isSelfService;

  return (
    <section className={className}>
      {/* Carousel */}
      {integration.long_details.carousel.length > 0 && (
        <DidomiCarousel
          class="mb-s"
          style={{ aspectRatio: '16 / 9', height: 'unset' }}
          with-shadow="true"
        >
          {integration.long_details.carousel.map(slide => (
            <DidomiCarouselSlide
              key={slide.url}
              mediaSrc={`${ASSETS_URL}/assets/${slide.url}`}
              mediaType={slide.type}
            />
          ))}
        </DidomiCarousel>
      )}

      {/* Promotional Push */}
      {isPromoPushVisible && (
        <IntegrationPromoPush
          pushTitle={promoPushTitle}
          pushImage={`${ASSETS_URL}/assets/pmp-preview.svg`}
          onBookDemoClicked={() => setDialogState('booking')}
        />
      )}

      {/* Pricing Card */}
      {isPricingVisible && (
        <IntegrationPricingCard
          startingPrice={integration.pricing}
          onBookDemoClicked={() => setDialogState('pre-booking')}
        />
      )}

      {/* Modals */}
      <PreBookingModal
        isOpen={dialogState === 'pre-booking'}
        onConfirm={() => setDialogState('booking')}
        onCancel={() => setDialogState(null)}
      />
      <BookingModal
        integration={integration}
        isPmpEnabled={isPmpEnabled}
        isOpen={dialogState === 'booking'}
        onCancel={() => setDialogState(null)}
      />
    </section>
  );
};
