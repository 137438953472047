import React from 'react';
import {
  DidomiAutocompleteWithSearchField,
  DidomiChip,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { useField } from 'formik';
import { MappingOption } from '@types';
import { MAPPING_EMPTY_VALUE } from '@utils';

interface MappingFieldProps {
  name: string;
  mappingOptions: MappingOption[];
}

export const MappingField = ({ name, mappingOptions }: MappingFieldProps) => {
  const hasMappingOptions = mappingOptions.length > 0;
  const [, meta, helpers] = useField(name);

  const isMapped = meta.initialValue || (meta.value && meta.touched && !meta.error);

  return (
    <div className="flex items-center gap-xs">
      {isMapped && <DidomiChip basicType="success" label="Mapped" rightIcon="union-sm" />}

      {/* Mapping Select */}
      {hasMappingOptions && (
        <DidomiAutocompleteWithSearchField
          name={name}
          size="xs"
          placeholder="No mapping"
          hide-error-message="true"
          items={mappingOptions}
        />
      )}

      {/* Mapping Input */}
      {!hasMappingOptions && (
        <DidomiTextInputField
          name={name}
          className="w-[150px]"
          maxlength={255}
          size="xs"
          placeholder="Type your text"
          hideErrorMessage={!meta.error}
          onValueChange={e => helpers.setValue(e.target.value || MAPPING_EMPTY_VALUE)}
        />
      )}
    </div>
  );
};
