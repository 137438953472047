import React from 'react';
import { tx } from '@twind/core';

interface InlineListProps {
  className?: string;
  items: string[];
  separator?: string;
  'data-cy'?: string;
}

export const InlineList = ({ items, separator, className, 'data-cy': dataCy }: InlineListProps) => {
  return (
    <ul className={tx('flex', className)} data-cy={dataCy}>
      {items.map((item, index) => (
        <li key={item}>
          {item}&nbsp;{index < items.length - 1 && <span>{separator}&nbsp;</span>}
        </li>
      ))}
    </ul>
  );
};
