import React, { useMemo, useRef } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiButton, DidomiEmptyState } from '@didomi/ui-atoms-react';
import {
  ExploreIntegrationsList,
  IntegrationsListToolbar,
  Loader,
  RequestIntegrationWidget,
} from '@components';
import { useIntegrationsApi } from '@hooks';
import { LoadingModal } from '@modals';
import { IntegrationsFilters } from '@types';
import { matchIntegrationsFilters } from '@utils';
import { INITIAL_TABLE_STATE, SEARCH_FIELDS } from './ExploreIntegrationsHelpers';

export const ExploreIntegrations = () => {
  const { integrations, isLoading, requestIntegration, isRequesting } = useIntegrationsApi();
  const listRef = useRef<HTMLUListElement>(null);

  const tableState = useTableState(INITIAL_TABLE_STATE);

  const { data: integrationsToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(integrations, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: SEARCH_FIELDS,
        filters: [matchIntegrationsFilters(tableState.filters as unknown as IntegrationsFilters)],
      }),
    [integrations, tableState],
  );

  const isEmpty = integrationsToDisplay?.length === 0;
  const hasMoreItems = integrationsToDisplay.length < paginationTotal;

  const loadMore = () => {
    // store the index of the first element from the next page - it the same as the current length of the displayed items
    const firstElementIndexFromTheNextPage = integrationsToDisplay.length;

    // increment limit by the pagination size
    const nextLimit = tableState.limit + INITIAL_TABLE_STATE.defaultLimit;
    tableState.changeLimit(new CustomEvent('', { detail: { limit: nextLimit } }));

    // Wait for the next render to scroll to the first element from the next page
    setTimeout(() => scrollToListElement(firstElementIndexFromTheNextPage), 0);
  };

  const scrollToListElement = (index: number) => {
    const element = listRef.current.children[index];
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="w-full">
      <IntegrationsListToolbar
        className="mb-xxs"
        numberOfResults={paginationTotal}
        integrations={integrations}
        onSearchChange={tableState.changeSearch}
        onFiltersChange={tableState.changeFilters}
      />

      {!isEmpty && (
        <>
          <ExploreIntegrationsList integrations={integrationsToDisplay} ref={listRef} />

          {/*  Pagination */}
          {hasMoreItems && (
            <div className="flex justify-center mt-12">
              <DidomiButton
                variant="secondary"
                data-cy="pagination-load-more"
                data-tracking="marketplace-explore-load-more-button"
                onClick={loadMore}
              >
                Load more
              </DidomiButton>
            </div>
          )}
        </>
      )}

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState illustration-name="traces-no-result-found">
          <div slot="title">It&apos;s empty here!</div>
          No results
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <Loader>Loading integrations</Loader>}
      <LoadingModal isOpen={isRequesting} title="Submitting request..." />

      <RequestIntegrationWidget onSubmit={requestIntegration} />
    </section>
  );
};
