import { NavLink, Outlet, useParams } from 'react-router-dom';
import React from 'react';
import {
  DidomiNavTabs,
  DidomiNavTabHeading,
  DidomiNavTabHeaders,
  DidomiNavTabHeader,
  DidomiNavTabContents,
  DidomiNavTabContent,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { useSubscriptionsApi, useSubscriptionSetup } from '@hooks';
import { RELATIVE_ROUTES } from '@utils';

const getActiveRouteClass = ({ isActive }: { isActive: boolean }) => {
  return isActive ? 'didomi-active-tab' : '';
};

export const ConfigureSubscriptionSteps = () => {
  const { integrationId } = useParams();
  const { isLoading } = useSubscriptionsApi(integrationId);
  const { isWithMapping, isWithOptions, subscriptionConfig, subscriptionConfigType } =
    useSubscriptionSetup(integrationId);

  return (
    <DidomiSkeleton isLoading={isLoading} className="w-full h-full">
      {/* Render tabs only when config type data is available */}
      {subscriptionConfigType && (
        <DidomiNavTabs style={{ '--tabs-change-transition': 'none' }}>
          <DidomiNavTabHeading variant="flow">
            <DidomiNavTabHeaders>
              <DidomiNavTabHeader>
                <NavLink
                  to={RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_SETTINGS}
                  className={getActiveRouteClass}
                >
                  1 - Settings
                </NavLink>
              </DidomiNavTabHeader>
              {isWithOptions && (
                <DidomiNavTabHeader disabled={!subscriptionConfig}>
                  <NavLink
                    to={RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_OPTIONS}
                    className={getActiveRouteClass}
                  >
                    2 - Options
                  </NavLink>
                </DidomiNavTabHeader>
              )}
              {isWithMapping && (
                <DidomiNavTabHeader disabled={!subscriptionConfig}>
                  <NavLink
                    to={RELATIVE_ROUTES.CONFIGURE_SUBSCRIPTION_MAPPINGS}
                    className={getActiveRouteClass}
                  >
                    {isWithOptions ? '3' : '2'} - Mapping
                  </NavLink>
                </DidomiNavTabHeader>
              )}
            </DidomiNavTabHeaders>
          </DidomiNavTabHeading>

          <DidomiNavTabContents>
            <DidomiNavTabContent>
              <section className="w-full h-full pt-m">
                <Outlet />
              </section>
            </DidomiNavTabContent>
          </DidomiNavTabContents>
        </DidomiNavTabs>
      )}
    </DidomiSkeleton>
  );
};
