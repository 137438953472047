import { SubscriptionConfigType, FormField, SubscriptionConfig } from '@types';
import { getFormFieldConfig } from './getFormFieldConfig';

// Name of additional form field (authentication required)
const FIELD_NAME_AUTHENTICATION_REQUIRED = 'authentication_required';

// Parent keys
const PARENT_KEY_AUTHENTICATION = 'authentication';
const PARENT_KEY_PROPERTIES = 'properties';

/*
 * Get settings form fields based on the subscription config type and subscription config
 */
export const getSettingsFormFields = (
  subscriptionConfigType: SubscriptionConfigType,
  subscriptionConfig: SubscriptionConfig,
): FormField[] => {
  if (!subscriptionConfigType) {
    return [];
  }

  const additionalFormFields = getAdditionalFormFields(subscriptionConfigType, subscriptionConfig);

  const authenticationFields = Object.entries(
    subscriptionConfigType.authentication_schema.properties,
  ).map(([fieldKey, fieldSchema]) => {
    const fieldConfig = getFormFieldConfig({
      fieldKey,
      fieldSchema,
      subscriptionConfig,
      parentKey: PARENT_KEY_AUTHENTICATION,
      requiredByDefault: subscriptionConfigType.authentication_schema.required.includes(fieldKey),
    });

    return {
      ...fieldSchema,
      ...fieldConfig,
      ...(subscriptionConfigType &&
        subscriptionConfigType.authentication_required === false && {
          dependsOn: FIELD_NAME_AUTHENTICATION_REQUIRED,
        }),
    };
  });

  const optionFields =
    subscriptionConfigType.properties_schema.required?.map(fieldKey => {
      const fieldSchema = subscriptionConfigType.properties_schema.properties[fieldKey];
      const fieldConfig = getFormFieldConfig({
        fieldKey,
        fieldSchema,
        subscriptionConfig,
        parentKey: PARENT_KEY_PROPERTIES,
        requiredByDefault: true,
      });

      return {
        ...fieldSchema,
        ...fieldConfig,
      };
    }) || [];

  return additionalFormFields.concat(authenticationFields, optionFields);
};

const getAdditionalFormFields = (
  subscriptionConfigType: SubscriptionConfigType,
  subscriptionConfig: SubscriptionConfig,
): FormField[] => {
  const additionalFormFields: FormField[] = [];

  const { authentication: authenticationValue } = subscriptionConfig || {};
  const { authentication_required: authenticationRequired } = subscriptionConfigType;

  if (subscriptionConfigType && authenticationRequired === false) {
    additionalFormFields.push({
      type: 'boolean',
      description: '',
      id: 'Enable authentication',
      required: true,
      formControlName: FIELD_NAME_AUTHENTICATION_REQUIRED,
      placeholder: '',
      title: 'You can enable this option to setup the authentication attributes.',
      value: authenticationValue && Object.keys(authenticationValue).length === 0 ? false : true,
    });
  }

  return additionalFormFields;
};
