import { useMemo } from 'react';
import { getMappingFormInitialValues, getMappingFormValidationSchema } from '@utils';
import { useConfigTree } from './useConfigTree.hook';
import { useSubscriptionMappingOptions } from './useSubscriptionMappingOptions.hook';
import { useSubscriptionSetup } from './useSubscriptionSetup.hook';

// Helpers hook that provides all the necessary data for the Subscription Mapping form
export const useSubscriptionMappingState = (integrationId: string) => {
  const { subscriptionConfig } = useSubscriptionSetup(integrationId);
  const { data: configTree, isLoading: isTreeLoading } = useConfigTree();
  const { data: mappingOptions = [], isLoading: isMappingOptionsLoading } =
    useSubscriptionMappingOptions(subscriptionConfig?.id);

  const selectedPurposes = useMemo(() => configTree?.selected_purposes || [], [configTree]);

  const initialValues = useMemo(() => {
    return getMappingFormInitialValues(selectedPurposes, subscriptionConfig?.mappings);
  }, [selectedPurposes, subscriptionConfig]);

  const validationSchema = useMemo(
    () => getMappingFormValidationSchema(initialValues),
    [initialValues],
  );

  const isLoading = isTreeLoading || isMappingOptionsLoading;

  return {
    selectedPurposes,
    initialValues,
    validationSchema,
    mappingOptions,
    isLoading,
  };
};
