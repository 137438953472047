import {
  FieldSchema,
  FormField,
  SubscriptionConfig,
  SubscriptionConfigSchemaDefinition,
  SubscriptionConfigType,
} from '@types';
import { getFormFieldConfig } from './getFormFieldConfig';

// Parent keys
const PARENT_KEY_PROPERTIES = 'properties';

/*
 * Return fields that are optional(that are not in the `required` array)
 */
const getOptionalFieldsEntries = (
  schemaDefinition: SubscriptionConfigSchemaDefinition,
): [string, FieldSchema][] => {
  return Object.entries(schemaDefinition.properties).filter(
    ([fieldKey]) => !schemaDefinition.required?.includes(fieldKey),
  );
};

/*
 * Get settings form fields based on the subscription-type
 */
export const getOptionsFormFields = (
  subscriptionConfigType: SubscriptionConfigType,
  subscriptionConfig: SubscriptionConfig,
): FormField[] => {
  if (!subscriptionConfigType) {
    return [];
  }

  const optionalFields: FormField[] = getOptionalFieldsEntries(
    subscriptionConfigType.properties_schema,
  ).map(([fieldKey, fieldSchema]) => ({
    ...fieldSchema,
    ...getFormFieldConfig({
      fieldKey,
      fieldSchema,
      subscriptionConfig,
      parentKey: PARENT_KEY_PROPERTIES,
      requiredByDefault: false,
    }),
  }));

  const additionalUiFields = getAdditionalFormFields(subscriptionConfigType, subscriptionConfig);

  return optionalFields.concat(additionalUiFields);
};

export const getAdditionalFormFields = (
  subscriptionConfigType: SubscriptionConfigType,
  subscriptionConfig: SubscriptionConfig,
): FormField[] => {
  const additionalFormFields: FormField[] = [];

  if (subscriptionConfigType.support_webhooks) {
    additionalFormFields.push({
      type: 'boolean',
      description: '',
      id: 'Bidirectional flow',
      required: true,
      value: subscriptionConfig?.enable_webhooks,
      formControlName: 'enable_webhooks',
      placeholder: '',
      title:
        'Enable this option to update your users preferences based on your third party profile updates.',
    });
    // display app_id field when there is bidirectional flow switch
    additionalFormFields.push({
      type: 'string',
      description:
        'Add the account’s ID from where events will be triggered through the Bidirectional flow',
      id: 'Account identifier',
      required: false,
      value: subscriptionConfig?.app_id,
      formControlName: 'app_id',
      placeholder: '',
      dependsOn: 'enable_webhooks',
    });
  }

  if (subscriptionConfigType.support_upsert) {
    additionalFormFields.push({
      type: 'boolean',
      description: '',
      id: 'Upsert mode',
      required: true,
      value: subscriptionConfig?.upsert_mode,
      formControlName: 'upsert_mode',
      placeholder: '',
      title:
        'By enabling this option, for non identified profiles Didomi will create a new instance while pushing the preferences to the 3rd party system.',
    });
  }

  if (subscriptionConfigType.support_emailing) {
    additionalFormFields.push({
      type: 'boolean',
      description: '',
      id: 'Enable for transactional email',
      required: true,
      value: subscriptionConfig?.enable_emailing,
      formControlName: 'enable_emailing',
      placeholder: '',
      title:
        'You can enable this option to setup your 3rd party email provider. Once enabled, please reach out to support@didomi.io to finalize the email configuration.',
    });
  }

  return additionalFormFields;
};
