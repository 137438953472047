import { UseQueryOptions, useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { SubscriptionConfigType } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

// Fetch subscription config types for a given list of typeIds
export const useSubscriptionConfigTypes = (
  typeIds: string[],
  options?: UseQueryOptions<SubscriptionConfigType[]>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchSubscriptionConfigType = async (typeId: string) => {
    const response = await axiosWithInterceptors.get<SubscriptionConfigType>(
      `${CONFIG.environmentConfig.apiBaseUrl}marketplace/integration-types/${typeId}`,
    );
    return response.data;
  };

  const fetchSubscriptionConfigTypes = async () => {
    const responses = await Promise.all(typeIds.map(fetchSubscriptionConfigType));
    return responses;
  };

  return useQuery(QUERY_KEYS.SUBSCRIPTION_CONFIG_TYPES(typeIds), fetchSubscriptionConfigTypes, {
    enabled: !!typeIds,
    ...options,
  });
};
