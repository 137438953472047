export enum PurchaseType {
  FREE = 'free',
  PAID = 'paid',
}

export enum SubscriptionType {
  PREMIUM_FEATURE = 'didomi_subscription',
  CONNECTOR = 'external_subscription',
}

export interface IntegrationsFilters {
  purchaseType: PurchaseType;
  subscriptionType: SubscriptionType;
  compatibilities: string[];
  categories: string[];
}
