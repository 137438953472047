import { useParams } from 'react-router-dom';
import React from 'react';
import {
  DidomiButton,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
} from '@didomi/ui-atoms-react';
import { ConfigurationForm } from '@components';
import { useSubscriptionSetup, useSubscriptionSetupNavigation, useSubscriptionsApi } from '@hooks';
import { BlockNavigationModal, LoadingModal } from '@modals';
import { transformConfigFormToPayload } from '@utils';

export const ConfigureSubscriptionOptions = () => {
  const { proceedToStep } = useSubscriptionSetupNavigation();
  const { integrationId } = useParams();

  const { subscriptionConfigTypes, isUpdating, updateConfig } = useSubscriptionsApi(integrationId);
  const { subscriptionConfig, optionsFormFields } = useSubscriptionSetup(integrationId);

  const getApiPayload = formValues => {
    const payload = transformConfigFormToPayload(
      formValues,
      {
        type_id: subscriptionConfig.type_id,
        properties: subscriptionConfig?.properties || {},
        authentication: subscriptionConfig?.authentication || {},
      },
      optionsFormFields,
    );

    return payload;
  };

  const handleSaveSettings = (values, proceedNavCb?: () => void) => {
    let payload = getApiPayload(values);
    // After save success, navigate to the next step or proceed with the router blocking navigation
    const onSuccess = () => (proceedNavCb ? proceedNavCb() : proceedToStep(3));

    updateConfig({ configId: subscriptionConfig.id, config: payload }, { onSuccess });
  };

  return (
    <div className="pb-[100px]">
      {optionsFormFields?.length > 0 && (
        <ConfigurationForm
          fields={optionsFormFields}
          typeSchemaSelect={
            // Type Schema Select
            subscriptionConfigTypes?.length > 1 && (
              <DidomiSelect
                label="Choose an integration type"
                value={subscriptionConfig.type_id}
                disabled={true} // The control is disabled as it is not allowed to change type after creation
              >
                <DidomiSelectOptions>
                  {subscriptionConfigTypes?.map(type => (
                    <DidomiSelectOption key={type.id} label={type.name} value={type.id} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelect>
            )
          }
          onSubmit={values => handleSaveSettings(values)}
        >
          {({ isValid, dirty, values }) => (
            <>
              {/* Form Actions */}
              <div className="fixed bottom-0 flex gap-xs justify-center w-full -mx-l p-xs bg-white border-solid border-t-1 border-neutral-gray-3">
                <DidomiButton type="submit" disabled={!isValid || !dirty}>
                  Save options
                </DidomiButton>
              </div>

              <BlockNavigationModal
                isBlocked={dirty && !isUpdating}
                isSaveAllowed={isValid}
                onSaveAndProceedClicked={proceedNavCb => handleSaveSettings(values, proceedNavCb)}
              />
            </>
          )}
        </ConfigurationForm>
      )}

      {/* Modals */}
      <LoadingModal isOpen={isUpdating} title="Saving changes..." />
    </div>
  );
};
