import { NavLink } from 'react-router-dom';
import React, { forwardRef } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiContentPromotionCard } from '@didomi/ui-atoms-react';
import { SPA_FULL_NAME } from '@constants';
import { Integration } from '@types';

interface ExploreIntegrationsListProps {
  integrations: Integration[];
}

export const ExploreIntegrationsList = forwardRef<HTMLUListElement, ExploreIntegrationsListProps>(
  function ExploreIntegrationsListFn({ integrations }, listRef) {
    const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);

    return (
      <ul ref={listRef} className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mb-7">
        {integrations.map(integration => (
          <li key={integration.id} className="scroll-m-xs">
            <NavLink
              className="!outline-none focus-visible:(ring-[3px] !ring-primary-blue-2) block h-full"
              data-tracking="marketplace-integration-card-link"
              to={integration.id}
            >
              <DidomiContentPromotionCard
                className="h-full w-full"
                description={integration.short_details.description}
                imgSrc={`${ASSETS_URL}/assets/${integration.short_details.logo_url}`}
                chips={integration.is_free ? ['Free'] : []}
                tags={integration.tags}
                compatibilities={integration.compatibilities}
                compatibilitiesLabel="Available for:"
                ctaText="Discover"
                aria-label={integration.name}
              />
            </NavLink>
          </li>
        ))}
      </ul>
    );
  },
);
