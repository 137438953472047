import { useMemo } from 'react';
import { matchId } from '@didomi/helpers';
import { useSnackbar } from '@didomi/utility-react';
import { useIntegrations } from './useIntegrations.hook';
import { useRequestIntegration } from './useRequestIntegration.hook';

export const useIntegrationsApi = (integrationId?: string) => {
  const { data: integrations, isLoading } = useIntegrations();
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while saving the changes`;
    displaySnackbar(message, { variant: 'error', duration: 5000 });
  };

  const integration = useMemo(() => {
    if (integrations?.length && integrationId) {
      return integrations?.find(matchId(integrationId));
    }
  }, [integrations, integrationId]);

  const { mutate: requestIntegration, isLoading: isRequesting } = useRequestIntegration({
    onSuccess: () => {
      displaySnackbar('Your request will be evaluated', { title: 'Thank you', icon: 'check' });
    },
    onError: handleApiError,
  });

  return {
    isLoading,
    integrations,
    integration,
    isRequesting,
    requestIntegration,
  };
};
