import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { useHasAccessPolicies, useSPARouter } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { SPA_FULL_NAME, ACCESS_POLICIES_CONFIG } from '@constants';
import { Integration } from '@types';
import { ROUTES } from '@utils';
import { SubscriptionsListCard } from 'components/SubscriptionsListCard/SubscriptionsListCard';

interface SubscriptionsListProps {
  className?: string;
  subscriptions: Integration[];
}

export const SubscriptionsList = ({ className, subscriptions }: SubscriptionsListProps) => {
  const { hasAccess: hasEditAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.MARKETPLACE_MANAGE_EDITOR,
  );
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();

  const navigateToManage = (subscription: Integration) => {
    if (subscription.feature_url_path) {
      // Navigate to the SPA module responsible for current integration
      navigateTo(subscription.feature_url_path);
    } else {
      // Navigate to the subscription settings page
      navigate(ROUTES.CONFIGURE_SUBSCRIPTION.replace(':integrationId', subscription.id));
    }
  };

  return (
    <ul className={tx('grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-s', className)}>
      {subscriptions.map(subscription => (
        <li key={subscription.id} aria-label={subscription.name}>
          <button
            className="w-full !outline-none focus-visible:(ring-[3px] !ring-primary-blue-2) block h-full"
            data-tracking="marketplace-subscription-card-link"
            data-cy="marketplace-subscription-card-link"
            disabled={!hasEditAccess}
            onClick={() => navigateToManage(subscription)}
          >
            <SubscriptionsListCard
              logoSrc={`${ASSETS_URL}/assets/${subscription.short_details.logo_url}`}
              hasEditAccess={hasEditAccess}
            />
          </button>
        </li>
      ))}
    </ul>
  );
};
