import { NavLink, Outlet } from 'react-router-dom';
import React from 'react';
import {
  DidomiFeatureHeader,
  DidomiNavTabHeaders,
  DidomiNavTabs,
  DidomiSmallPush,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabContent,
  DidomiNavTabContents,
} from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@constants';
import { ROUTES } from '@utils';

const NO_ACCESS_TAB_TOOLTIP =
  'You do not have permission to view this tab. Please reach out to your administrator.';

export const HomeLayout = () => {
  const { hasAccess: hasAccessToManage } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.MARKETPLACE_MANAGE_VIEWER,
  );

  return (
    <section className="p-l pb-20 text-primary-blue-6">
      <DidomiFeatureHeader titleText="Marketplace" className="w-full block mb-s">
        <div slot="description">
          Discover a catalog of no-code integrations and features to provide you the best privacy
          management experience
        </div>

        <DidomiSmallPush
          href="https://developers.didomi.io/integrations/preference-management-platform-integrations"
          target="_blank"
          slot="actions"
          text="Learn more!"
          highlighted-text="Your configuration guide"
          smallIcon="right-on"
        ></DidomiSmallPush>
      </DidomiFeatureHeader>

      <DidomiNavTabs>
        <DidomiNavTabHeading>
          <DidomiNavTabHeaders>
            <DidomiNavTabHeader>
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.EXPLORE}
              >
                Explore
              </NavLink>
            </DidomiNavTabHeader>
            <DidomiNavTabHeader
              disabled={!hasAccessToManage}
              disabledTooltip={NO_ACCESS_TAB_TOOLTIP}
              iconRight={hasAccessToManage ? '' : 'lock'}
            >
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.MANAGE}
              >
                Manage
              </NavLink>
            </DidomiNavTabHeader>
          </DidomiNavTabHeaders>
        </DidomiNavTabHeading>

        <DidomiNavTabContents>
          <DidomiNavTabContent className="pt-m">
            <Outlet />
          </DidomiNavTabContent>
        </DidomiNavTabContents>
      </DidomiNavTabs>
    </section>
  );
};
