export const themePreset = {
  theme: {
    backgroundImage: {
      'setup-form-card': 'linear-gradient(314.69deg, #eff7fb 0%, #f9fafa 100%)',
    },
    boxShadow: {
      'setup-form-card': `
        0px 100px 80px rgba(0, 0, 0, 0.08), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916);
      `,
    },
  },
};
