// Transform the form values to the payload that does not have empty ids
export const transformMappingFormToPayload = (formValues: Record<string, string>) => {
  const hasId = ([, id]: [string, string]) => !!id;

  return Object.entries(formValues)
    .filter(hasId)
    .map(([value, id]) => ({
      id,
      value,
      type: 'pmp-widget-id',
    }));
};
