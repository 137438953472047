import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

// Fetch premium features for the organization
export const usePremiumFeatures = (options?: UseQueryOptions<Record<string, boolean>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchPremiumFeatures = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Record<string, boolean>>>(
      `${CONFIG.environmentConfig.apiBaseUrl}premium-features?organization_id=${organizationId}`,
    );
    return response.data.data[0];
  };

  return useQuery(QUERY_KEYS.PREMIUM_FEATURES(organizationId), fetchPremiumFeatures, options);
};
