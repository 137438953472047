import React from 'react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { UtilityProvider } from '@didomi/utility-react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ApiError, reportSpaError } from '@utils';
import { OrganizationRouterProvider } from 'router';
import './styles.css';
import { themePreset } from 'themePreset';

// Setup Twind for Marketplace SPA only
connect('.marketplace-root', { presets: [themePreset] });

// Handle queries errors
const queryCache = new QueryCache({
  onError: (error: Error) => reportSpaError(new ApiError(error)),
});

// Handle mutations errors
const mutationCache = new MutationCache({
  onError: (error: Error) => reportSpaError(new ApiError(error)),
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  },
});

const Root = () => {
  return (
    <UtilityProvider utility={utility}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <OrganizationRouterProvider />
      </QueryClientProvider>
    </UtilityProvider>
  );
};

export { Root };
